// import * as React from 'react';


export const time = [
    {
        id: 1,
        label: '14_Q1',
    },
    {
        id: 2,
        label: '14_Q2',
    },
    {
        id: 3,
        label: '14_Q3',
    },
    {
        id: 4,
        label: '14_Q4',
    },
    {
        id: 5,
        label: '15_Q1',
    },
    {
        id: 6,
        label: '15_Q2',
    },
    {
        id: 7,
        label: '15_Q3',
    },
    {
        id: 8,
        label: '15_Q4',
    },
    {
        id: 9,
        label: '16_Q1',
    },
    {
        id: 10,
        label: '16_Q2',
    },
    {
        id: 11,
        label: '16_Q3',
    },
    {
        id: 12,
        label: '16_Q4',
    },
    {
        id: 13,
        label: '17_Q1',
    },
    {
        id: 14,
        label: '17_Q2',
    },
    {
        id: 15,
        label: '17_Q3',
    },
    {
        id: 16,
        label: '17_Q4',
    },
    {
        id: 17,
        label: '18_Q1',
    },
    {
        id: 18,
        label: '18_Q2',
    },
    {
        id: 19,
        label: '18_Q3',
    },
    {
        id: 20,
        label: '18_Q4',
    },
    {
        id: 21,
        label: '19_Q1',
    },
    {
        id: 22,
        label: '19_Q2',
    },
    {
        id: 23,
        label: '19_Q3',
    },
    {
        id: 24,
        label: '19_Q4',
    },
    {
        id: 25,
        label: '20_Q1',
    },
    {
        id: 26,
        label: '20_Q2',
    },
    {
        id: 27,
        label: '20_Q3',
    },
    {
        id: 28,
        label: '20_Q4',
    },
    {
        id: 29,
        label: '21_Q1',
    },
    {
        id: 30,
        label: '21_Q2',
    },
    {
        id: 31,
        label: '21_Q3',
    },
  ];


// export const suburbAreas = [
//     {
//         id:1,
//         "14_Q1":2577,
//         "14_Q2": 2433,
//         "14_Q3": 2574,
//         "14_Q4": 2453,
//         "15_Q1": 2379,
//         "15_Q2": 2346,
//         "15_Q3": 2502,
//         "15_Q4": 2487,
//         "16_Q1": 2246,
//         "16_Q2": 2604,
//         "16_Q3": 2817,
//         "16_Q4": 2719,
//         "17_Q1": 2926,
//         "17_Q2": 2945,
//         "17_Q3": 2878,
//         "17_Q4": 2780,
//         "18_Q1": 2727,
//         "18_Q2": 2544,
//         "18_Q3": 2993,
//         "18_Q4": 2908,
//         "19_Q1": 2961,
//         "19_Q2": 2914,
//         "19_Q3": 2624,
//         "19_Q4": 2733,
//         "20_Q1": 2908,
//         "20_Q2": 2933,
//         "20_Q3": 2664,
//         "20_Q4": 2803,
//         "21_Q1": 2245,
//         "21_Q2": 2594,
//         "21_Q3": 1669,
//     }
// ]


export const suburbAreasRef = [
    {
        "GREATERBELCONNEN": 0,
        "GREATERGUNGAHLIN": 1,
        "INNERNORTH": 2,
        "INNERSOUTH": 3,
        "WESTONCREEK": 4,
        "MOLONGLODISTRICT": 5,
        "WODENCREEK": 6,
        "TUGGERANONGCREEK": 7,
        "MISC": 8,
        "CANBERRA": 9,
    }
]    


// export const suburbAreasRef = [
//     {
//         0: "BELCONNEN",
//         1: "GUNGAHLIN",
//         2: "INNERNORTH",
//         3: "INNERSOUTH",
//         4: "WESTONCREEK",
//         5: "MOLONGLODISTRICT",
//         6: "WODENCREEK",
//         7: "TUGGERANONGCREEK",
//         8: "MISC",
//     }
// ]    



export const suburbAreas = [
    {
        id: "GREATERBELCONNEN",
        range: {m: 0, n:25},
        values: [
            {
                "14_Q1": 2577,
                "14_Q2": 2433,
                "14_Q3": 2574,
                "14_Q4": 2453,
                "15_Q1": 2379,
                "15_Q2": 2346,
                "15_Q3": 2502,
                "15_Q4": 2487,
                "16_Q1": 2246,
                "16_Q2": 2604,
                "16_Q3": 2817,
                "16_Q4": 2719,
                "17_Q1": 2926,
                "17_Q2": 2945,
                "17_Q3": 2878,
                "17_Q4": 2780,
                "18_Q1": 2727,
                "18_Q2": 2544,
                "18_Q3": 2993,
                "18_Q4": 2908,
                "19_Q1": 2961,
                "19_Q2": 2914,
                "19_Q3": 2624,
                "19_Q4": 2733,
                "20_Q1": 2908,
                "20_Q2": 2933,
                "20_Q3": 2664,
                "20_Q4": 2803,
                "21_Q1": 2245,
                "21_Q2": 2594,
                "21_Q3": 1669,
            }
        ]
    },
    {
        id: "GREATERGUNGAHLIN",
        range: {m: 26, n:43},
        values: [
            {
                "14_Q1":1089,
                "14_Q2": 1077,
                "14_Q3": 994,
                "14_Q4": 1052,
                "15_Q1": 976,
                "15_Q2": 1169,
                "15_Q3": 991,
                "15_Q4": 1340,
                "16_Q1": 1244,
                "16_Q2": 1313,
                "16_Q3": 1477,
                "16_Q4": 1255,
                "17_Q1": 1303,
                "17_Q2": 1355,
                "17_Q3": 1555,
                "17_Q4": 1540,
                "18_Q1": 1157,
                "18_Q2": 1197,
                "18_Q3": 1319,
                "18_Q4": 1064,
                "19_Q1": 1319,
                "19_Q2": 1294,
                "19_Q3": 1275,
                "19_Q4": 1514,
                "20_Q1": 1416,
                "20_Q2": 1317,
                "20_Q3": 1275,
                "20_Q4": 1314,
                "21_Q1": 1167,
                "21_Q2": 1406,
                "21_Q3": 961,
            }
        ]
    },
    {
        id: "INNERNORTH",
        range: {m: 44, n:61},
        values: [
            {
                "14_Q1":3172,
                "14_Q2": 2669,
                "14_Q3": 2872,
                "14_Q4": 2578,
                "15_Q1": 2494,
                "15_Q2": 2659,
                "15_Q3": 2599,
                "15_Q4": 2589,
                "16_Q1": 2532,
                "16_Q2": 2498,
                "16_Q3": 2594,
                "16_Q4": 2518,
                "17_Q1": 2921,
                "17_Q2": 2693,
                "17_Q3": 2628,
                "17_Q4": 2663,
                "18_Q1": 2633,
                "18_Q2": 2311,
                "18_Q3": 2507,
                "18_Q4": 2516,
                "19_Q1": 2732,
                "19_Q2": 2438,
                "19_Q3": 2157,
                "19_Q4": 2471,
                "20_Q1": 2508,
                "20_Q2": 1795,
                "20_Q3": 1906,
                "20_Q4": 2281,
                "21_Q1": 1972,
                "21_Q2": 2284,
                "21_Q3": 1935,
            }
        ]
    },
    {
        id: "INNERSOUTH",
        range: {m: 62, n:76},
        values: [
            {
                "14_Q1":1190,
                "14_Q2": 1276,
                "14_Q3": 1295,
                "14_Q4": 1248,
                "15_Q1": 1109,
                "15_Q2": 1451,
                "15_Q3": 1548,
                "15_Q4": 1415,
                "16_Q1": 1272,
                "16_Q2": 1381,
                "16_Q3": 1434,
                "16_Q4": 1373,
                "17_Q1": 1279,
                "17_Q2": 1596,
                "17_Q3": 1395,
                "17_Q4": 1409,
                "18_Q1": 1319,
                "18_Q2": 1250,
                "18_Q3": 1470,
                "18_Q4": 1233,
                "19_Q1": 1358,
                "19_Q2": 936,
                "19_Q3": 946,
                "19_Q4": 1110,
                "20_Q1": 1275,
                "20_Q2": 965,
                "20_Q3": 1092,
                "20_Q4": 989,
                "21_Q1": 993,
                "21_Q2": 1236,
                "21_Q3": 815,
            }
        ]
    },
    {
        id: "WESTONCREEK",
        range: {m: 77, n:85},
        values: [
            {
                "14_Q1":404,
                "14_Q2": 361,
                "14_Q3": 431,
                "14_Q4": 430,
                "15_Q1": 468,
                "15_Q2": 473,
                "15_Q3": 502,
                "15_Q4": 558,
                "16_Q1": 532,
                "16_Q2": 478,
                "16_Q3": 399,
                "16_Q4": 390,
                "17_Q1": 380,
                "17_Q2": 359,
                "17_Q3": 465,
                "17_Q4": 467,
                "18_Q1": 433,
                "18_Q2": 328,
                "18_Q3": 301,
                "18_Q4": 366,
                "19_Q1": 322,
                "19_Q2": 398,
                "19_Q3": 390,
                "19_Q4": 512,
                "20_Q1": 471,
                "20_Q2": 448,
                "20_Q3": 373,
                "20_Q4": 361,
                "21_Q1": 339,
                "21_Q2": 353,
                "21_Q3": 275,
            }
        ]
    },
    {
        id: "MOLONGLODISTRICT",
        range: {m: 86, n:89},
        values: [
            {
                "14_Q1":13,
                "14_Q2": 15,
                "14_Q3": 35,
                "14_Q4": 18,
                "15_Q1": 49,
                "15_Q2": 49,
                "15_Q3": 127,
                "15_Q4": 79,
                "16_Q1": 82,
                "16_Q2": 113,
                "16_Q3": 150,
                "16_Q4": 83,
                "17_Q1": 136,
                "17_Q2": 147,
                "17_Q3": 238,
                "17_Q4": 195,
                "18_Q1": 102,
                "18_Q2": 152,
                "18_Q3": 138,
                "18_Q4": 109,
                "19_Q1": 136,
                "19_Q2": 180,
                "19_Q3": 127,
                "19_Q4": 193,
                "20_Q1": 182,
                "20_Q2": 117,
                "20_Q3": 170,
                "20_Q4": 241,
                "21_Q1": 164,
                "21_Q2": 194,
                "21_Q3": 117,
            }
        ]
    },
    {
        id: "WODENCREEK",
        range: {m: 90, n:101},
        values: [
            {
                "14_Q1":883,
                "14_Q2": 983,
                "14_Q3": 1064,
                "14_Q4": 1011,
                "15_Q1": 821,
                "15_Q2": 951,
                "15_Q3": 944,
                "15_Q4": 950,
                "16_Q1": 677,
                "16_Q2": 853,
                "16_Q3": 974,
                "16_Q4": 846,
                "17_Q1": 967,
                "17_Q2": 952,
                "17_Q3": 1082,
                "17_Q4": 1032,
                "18_Q1": 1064,
                "18_Q2": 1059,
                "18_Q3": 834,
                "18_Q4": 804,
                "19_Q1": 705,
                "19_Q2": 794,
                "19_Q3": 641,
                "19_Q4": 742,
                "20_Q1": 736,
                "20_Q2": 642,
                "20_Q3": 530,
                "20_Q4": 578,
                "21_Q1": 641,
                "21_Q2": 621,
                "21_Q3": 491,
            }
        ]
    },
    {
        id: "TUGGERANONGCREEK",
        range: {m: 102, n:119},
        values: [
            {
                "14_Q1":1856,
                "14_Q2": 1869,
                "14_Q3": 2262,
                "14_Q4": 1940,
                "15_Q1": 1956,
                "15_Q2": 2167,
                "15_Q3": 2129,
                "15_Q4": 2013,
                "16_Q1": 1764,
                "16_Q2": 1905,
                "16_Q3": 2100,
                "16_Q4": 1869,
                "17_Q1": 2058,
                "17_Q2": 2249,
                "17_Q3": 2031,
                "17_Q4": 1705,
                "18_Q1": 1723,
                "18_Q2": 1762,
                "18_Q3": 1684,
                "18_Q4": 1612,
                "19_Q1": 1680,
                "19_Q2": 1566,
                "19_Q3": 1665,
                "19_Q4": 1813,
                "20_Q1": 1705,
                "20_Q2": 1634,
                "20_Q3": 1318,
                "20_Q4": 1399,
                "21_Q1": 1373,
                "21_Q2": 1269,
                "21_Q3": 1037,
            }
        ]
    },
    {
        id: "MISC",
        range: {m: 120, n:123},
        values: [
            {
                "14_Q1":106,
                "14_Q2": 132,
                "14_Q3": 167,
                "14_Q4": 89,
                "15_Q1": 84,
                "15_Q2": 126,
                "15_Q3": 137,
                "15_Q4": 102,
                "16_Q1": 124,
                "16_Q2": 132,
                "16_Q3": 122,
                "16_Q4": 113,
                "17_Q1": 72,
                "17_Q2": 204,
                "17_Q3": 93,
                "17_Q4": 91,
                "18_Q1": 120,
                "18_Q2": 129,
                "18_Q3": 145,
                "18_Q4": 106,
                "19_Q1": 126,
                "19_Q2": 76,
                "19_Q3": 121,
                "19_Q4": 76,
                "20_Q1": 95,
                "20_Q2": 215,
                "20_Q3": 101,
                "20_Q4": 88,
                "21_Q1": 103,
                "21_Q2": 117,
                "21_Q3": 51,
            }
        ]
    },
    {
        id: "CANBERRA",
        range: {m: 124, n: 132},
        values: []
    }
]    



export const suburbs = [
    {
        id: "ARANDA",
        values: [
            {
                "14_Q1": 138,
                "14_Q2": 154,
                "14_Q3": 169,
                "14_Q4": 181,
                "15_Q1": 128,
                "15_Q2": 103,
                "15_Q3": 123,
                "15_Q4": 159,
                "16_Q1": 117,
                "16_Q2": 100,
                "16_Q3": 156,
                "16_Q4": 140,
                "17_Q1": 129,
                "17_Q2": 158,
                "17_Q3": 150,
                "17_Q4": 275,
                "18_Q1": 147,
                "18_Q2": 142,
                "18_Q3": 181,
                "18_Q4": 147,
                "19_Q1": 98,
                "19_Q2": 108,
                "19_Q3": 119,
                "19_Q4": 137,
                "20_Q1": 123,
                "20_Q2": 173,
                "20_Q3": 162,
                "20_Q4": 281,
                "21_Q1": 156,
                "21_Q2": 161,
                "21_Q3": 129,
            }
        ]
    },
    {
        id: "BELCONNEN",
        values: [
            {
                "14_Q1":671,
                "14_Q2": 584,
                "14_Q3": 588,
                "14_Q4": 677,
                "15_Q1": 690,
                "15_Q2": 655,
                "15_Q3": 576,
                "15_Q4": 745,
                "16_Q1": 604,
                "16_Q2": 634,
                "16_Q3": 682,
                "16_Q4": 717,
                "17_Q1": 730,
                "17_Q2": 819,
                "17_Q3": 600,
                "17_Q4": 651,
                "18_Q1": 628,
                "18_Q2": 756,
                "18_Q3": 867,
                "18_Q4": 792,
                "19_Q1": 774,
                "19_Q2": 765,
                "19_Q3": 764,
                "19_Q4": 793,
                "20_Q1": 812,
                "20_Q2": 803,
                "20_Q3": 769,
                "20_Q4": 636,
                "21_Q1": 534,
                "21_Q2": 718,
                "21_Q3": 487,
            }
        ]
    },
    {
        id: "BRUCE",
        values: [
            {
                "14_Q1":202,
                "14_Q2": 315,
                "14_Q3": 254,
                "14_Q4": 282,
                "15_Q1": 285,
                "15_Q2": 241,
                "15_Q3": 349,
                "15_Q4": 239,
                "16_Q1": 221,
                "16_Q2": 258,
                "16_Q3": 252,
                "16_Q4": 240,
                "17_Q1": 224,
                "17_Q2": 334,
                "17_Q3": 311,
                "17_Q4": 262,
                "18_Q1": 353,
                "18_Q2": 217,
                "18_Q3": 320,
                "18_Q4": 246,
                "19_Q1": 256,
                "19_Q2": 316,
                "19_Q3": 205,
                "19_Q4": 175,
                "20_Q1": 256,
                "20_Q2": 232,
                "20_Q3": 264,
                "20_Q4": 314,
                "21_Q1": 181,
                "21_Q2": 216,
                "21_Q3": 91,
            }
        ]
    },
    {
        id: "CHARNWOOD",
        values: [
            {
                "14_Q1":94,
                "14_Q2": 69,
                "14_Q3": 71,
                "14_Q4": 76,
                "15_Q1": 84,
                "15_Q2": 69,
                "15_Q3": 99,
                "15_Q4": 97,
                "16_Q1": 60,
                "16_Q2": 107,
                "16_Q3": 105,
                "16_Q4": 119,
                "17_Q1": 96,
                "17_Q2": 95,
                "17_Q3": 95,
                "17_Q4": 69,
                "18_Q1": 89,
                "18_Q2": 87,
                "18_Q3": 84,
                "18_Q4": 108,
                "19_Q1": 115,
                "19_Q2": 95,
                "19_Q3": 65,
                "19_Q4": 73,
                "20_Q1": 96,
                "20_Q2": 81,
                "20_Q3": 101,
                "20_Q4": 71,
                "21_Q1": 59,
                "21_Q2": 54,
                "21_Q3": 79,
            }
        ]
    },
    {
        id: "COOK",
        values: [
            {
                "14_Q1":44,
                "14_Q2": 59,
                "14_Q3": 159,
                "14_Q4": 89,
                "15_Q1": 54,
                "15_Q2": 90,
                "15_Q3": 38,
                "15_Q4": 55,
                "16_Q1": 41,
                "16_Q2": 46,
                "16_Q3": 135,
                "16_Q4": 76,
                "17_Q1": 82,
                "17_Q2": 67,
                "17_Q3": 127,
                "17_Q4": 106,
                "18_Q1": 86,
                "18_Q2": 105,
                "18_Q3": 129,
                "18_Q4": 67,
                "19_Q1": 67,
                "19_Q2": 86,
                "19_Q3": 60,
                "19_Q4": 63,
                "20_Q1": 85,
                "20_Q2": 111,
                "20_Q3": 58,
                "20_Q4": 99,
                "21_Q1": 72,
                "21_Q2": 63,
                "21_Q3": 30,
            }
        ]
    },
    {
        id: "DUNLOP",
        values: [
            {
                "14_Q1":81,
                "14_Q2": 63,
                "14_Q3": 64,
                "14_Q4": 74,
                "15_Q1": 69,
                "15_Q2": 48,
                "15_Q3": 42,
                "15_Q4": 61,
                "16_Q1": 89,
                "16_Q2": 130,
                "16_Q3": 82,
                "16_Q4": 72,
                "17_Q1": 78,
                "17_Q2": 51,
                "17_Q3": 62,
                "17_Q4": 45,
                "18_Q1": 46,
                "18_Q2": 71,
                "18_Q3": 93,
                "18_Q4": 79,
                "19_Q1": 83,
                "19_Q2": 61,
                "19_Q3": 54,
                "19_Q4": 54,
                "20_Q1": 66,
                "20_Q2": 69,
                "20_Q3": 46,
                "20_Q4": 61,
                "21_Q1": 46,
                "21_Q2": 51,
                "21_Q3": 33,
            }
        ]
    },
    {
        id: "EVATT",
        values: [
            {
                "14_Q1":103,
                "14_Q2": 93,
                "14_Q3": 60,
                "14_Q4": 65,
                "15_Q1": 80,
                "15_Q2": 56,
                "15_Q3": 62,
                "15_Q4": 80,
                "16_Q1": 48,
                "16_Q2": 82,
                "16_Q3": 58,
                "16_Q4": 80,
                "17_Q1": 114,
                "17_Q2": 98,
                "17_Q3": 74,
                "17_Q4": 62,
                "18_Q1": 50,
                "18_Q2": 55,
                "18_Q3": 62,
                "18_Q4": 99,
                "19_Q1": 61,
                "19_Q2": 73,
                "19_Q3": 52,
                "19_Q4": 38,
                "20_Q1": 48,
                "20_Q2": 57,
                "20_Q3": 48,
                "20_Q4": 52,
                "21_Q1": 55,
                "21_Q2": 37,
                "21_Q3": 51,
            }
        ]
    },
    {
        id: "FLOREY",
        values: [
            {
                "14_Q1":123,
                "14_Q2": 143,
                "14_Q3": 143,
                "14_Q4": 110,
                "15_Q1": 121,
                "15_Q2": 126,
                "15_Q3": 114,
                "15_Q4": 118,
                "16_Q1": 87,
                "16_Q2": 106,
                "16_Q3": 113,
                "16_Q4": 118,
                "17_Q1": 146,
                "17_Q2": 159,
                "17_Q3": 137,
                "17_Q4": 177,
                "18_Q1": 138,
                "18_Q2": 108,
                "18_Q3": 134,
                "18_Q4": 110,
                "19_Q1": 117,
                "19_Q2": 121,
                "19_Q3": 123,
                "19_Q4": 106,
                "20_Q1": 93,
                "20_Q2": 135,
                "20_Q3": 132,
                "20_Q4": 103,
                "21_Q1": 78,
                "21_Q2": 82,
                "21_Q3": 53,
            }
        ]
    },
    {
        id: "FLYNN",
        values: [
            {
                "14_Q1":42,
                "14_Q2": 29,
                "14_Q3": 36,
                "14_Q4": 30,
                "15_Q1": 30,
                "15_Q2": 27,
                "15_Q3": 32,
                "15_Q4": 37,
                "16_Q1": 38,
                "16_Q2": 45,
                "16_Q3": 30,
                "16_Q4": 47,
                "17_Q1": 39,
                "17_Q2": 33,
                "17_Q3": 55,
                "17_Q4": 49,
                "18_Q1": 53,
                "18_Q2": 31,
                "18_Q3": 35,
                "18_Q4": 31,
                "19_Q1": 45,
                "19_Q2": 58,
                "19_Q3": 27,
                "19_Q4": 22,
                "20_Q1": 44,
                "20_Q2": 34,
                "20_Q3": 26,
                "20_Q4": 41,
                "21_Q1": 36,
                "21_Q2": 30,
                "21_Q3": 19,
            }
        ]
    },
    {
        id: "FRASER",
        values: [
            {
                "14_Q1":29,
                "14_Q2": 23,
                "14_Q3": 27,
                "14_Q4": 23,
                "15_Q1": 18,
                "15_Q2": 26,
                "15_Q3": 20,
                "15_Q4": 15,
                "16_Q1": 13,
                "16_Q2": 68,
                "16_Q3": 29,
                "16_Q4": 42,
                "17_Q1": 46,
                "17_Q2": 23,
                "17_Q3": 17,
                "17_Q4": 28,
                "18_Q1": 32,
                "18_Q2": 40,
                "18_Q3": 33,
                "18_Q4": 16,
                "19_Q1": 29,
                "19_Q2": 37,
                "19_Q3": 50,
                "19_Q4": 27,
                "20_Q1": 42,
                "20_Q2": 28,
                "20_Q3": 15,
                "20_Q4": 27,
                "21_Q1": 16,
                "21_Q2": 24,
                "21_Q3": 19,
            }
        ]
    },
    {
        id: "GIRALANG",
        values: [
            {
                "14_Q1":53,
                "14_Q2": 54,
                "14_Q3": 50,
                "14_Q4": 46,
                "15_Q1": 44,
                "15_Q2": 58,
                "15_Q3": 41,
                "15_Q4": 49,
                "16_Q1": 54,
                "16_Q2": 53,
                "16_Q3": 36,
                "16_Q4": 47,
                "17_Q1": 53,
                "17_Q2": 57,
                "17_Q3": 68,
                "17_Q4": 70,
                "18_Q1": 62,
                "18_Q2": 49,
                "18_Q3": 48,
                "18_Q4": 60,
                "19_Q1": 73,
                "19_Q2": 44,
                "19_Q3": 30,
                "19_Q4": 79,
                "20_Q1": 46,
                "20_Q2": 43,
                "20_Q3": 49,
                "20_Q4": 29,
                "21_Q1": 54,
                "21_Q2": 89,
                "21_Q3": 47,
            }
        ]
    },
    {
        id: "HAWKER",
        values: [
            {
                "14_Q1":96,
                "14_Q2": 84,
                "14_Q3": 99,
                "14_Q4": 70,
                "15_Q1": 58,
                "15_Q2": 96,
                "15_Q3": 79,
                "15_Q4": 101,
                "16_Q1": 117,
                "16_Q2": 90,
                "16_Q3": 140,
                "16_Q4": 86,
                "17_Q1": 106,
                "17_Q2": 139,
                "17_Q3": 118,
                "17_Q4": 99,
                "18_Q1": 100,
                "18_Q2": 97,
                "18_Q3": 93,
                "18_Q4": 114,
                "19_Q1": 97,
                "19_Q2": 136,
                "19_Q3": 219,
                "19_Q4": 185,
                "20_Q1": 335,
                "20_Q2": 217,
                "20_Q3": 133,
                "20_Q4": 228,
                "21_Q1": 123,
                "21_Q2": 190,
                "21_Q3": 75,
            }
        ]
    },
    {
        id: "HIGGINS",
        values: [
            {
                "14_Q1":50,
                "14_Q2": 38,
                "14_Q3": 60,
                "14_Q4": 26,
                "15_Q1": 50,
                "15_Q2": 44,
                "15_Q3": 44,
                "15_Q4": 31,
                "16_Q1": 40,
                "16_Q2": 66,
                "16_Q3": 42,
                "16_Q4": 63,
                "17_Q1": 54,
                "17_Q2": 53,
                "17_Q3": 62,
                "17_Q4": 55,
                "18_Q1": 66,
                "18_Q2": 50,
                "18_Q3": 72,
                "18_Q4": 78,
                "19_Q1": 61,
                "19_Q2": 54,
                "19_Q3": 68,
                "19_Q4": 60,
                "20_Q1": 56,
                "20_Q2": 75,
                "20_Q3": 71,
                "20_Q4": 48,
                "21_Q1": 56,
                "21_Q2": 64,
                "21_Q3": 37,
            }
        ]
    },
    {
        id: "HOLT",
        values: [
            {
                "14_Q1":160,
                "14_Q2": 173,
                "14_Q3": 169,
                "14_Q4": 139,
                "15_Q1": 121,
                "15_Q2": 210,
                "15_Q3": 157,
                "15_Q4": 169,
                "16_Q1": 176,
                "16_Q2": 206,
                "16_Q3": 247,
                "16_Q4": 198,
                "17_Q1": 232,
                "17_Q2": 193,
                "17_Q3": 190,
                "17_Q4": 148,
                "18_Q1": 158,
                "18_Q2": 146,
                "18_Q3": 181,
                "18_Q4": 161,
                "19_Q1": 173,
                "19_Q2": 253,
                "19_Q3": 201,
                "19_Q4": 222,
                "20_Q1": 198,
                "20_Q2": 194,
                "20_Q3": 228,
                "20_Q4": 176,
                "21_Q1": 202,
                "21_Q2": 212,
                "21_Q3": 117,
            }
        ]
    },
    {
        id: "KALEEN",
        values: [
            {
                "14_Q1":100,
                "14_Q2": 132,
                "14_Q3": 152,
                "14_Q4": 120,
                "15_Q1": 126,
                "15_Q2": 90,
                "15_Q3": 240,
                "15_Q4": 97,
                "16_Q1": 125,
                "16_Q2": 91,
                "16_Q3": 75,
                "16_Q4": 126,
                "17_Q1": 112,
                "17_Q2": 134,
                "17_Q3": 167,
                "17_Q4": 116,
                "18_Q1": 151,
                "18_Q2": 92,
                "18_Q3": 86,
                "18_Q4": 110,
                "19_Q1": 115,
                "19_Q2": 102,
                "19_Q3": 113,
                "19_Q4": 114,
                "20_Q1": 94,
                "20_Q2": 145,
                "20_Q3": 103,
                "20_Q4": 123,
                "21_Q1": 98,
                "21_Q2": 103,
                "21_Q3": 69,
            }
        ]
    },
    {
        id: "LATHAM",
        values: [
            {
                "14_Q1":68,
                "14_Q2": 38,
                "14_Q3": 48,
                "14_Q4": 65,
                "15_Q1": 72,
                "15_Q2": 27,
                "15_Q3": 47,
                "15_Q4": 43,
                "16_Q1": 47,
                "16_Q2": 55,
                "16_Q3": 52,
                "16_Q4": 54,
                "17_Q1": 84,
                "17_Q2": 46,
                "17_Q3": 59,
                "17_Q4": 51,
                "18_Q1": 73,
                "18_Q2": 53,
                "18_Q3": 54,
                "18_Q4": 84,
                "19_Q1": 66,
                "19_Q2": 79,
                "19_Q3": 56,
                "19_Q4": 57,
                "20_Q1": 44,
                "20_Q2": 51,
                "20_Q3": 34,
                "20_Q4": 51,
                "21_Q1": 51,
                "21_Q2": 60,
                "21_Q3": 49,
            }
        ]
    },
    {
        id: "LAWSON",
        values: [
            {
                "14_Q1":10,
                "14_Q2": 5,
                "14_Q3": 12,
                "14_Q4": 9,
                "15_Q1": 5,
                "15_Q2": 6,
                "15_Q3": 3,
                "15_Q4": 5,
                "16_Q1": 10,
                "16_Q2": 9,
                "16_Q3": 11,
                "16_Q4": 14,
                "17_Q1": 49,
                "17_Q2": 13,
                "17_Q3": 16,
                "17_Q4": 28,
                "18_Q1": 23,
                "18_Q2": 34,
                "18_Q3": 49,
                "18_Q4": 19,
                "19_Q1": 30,
                "19_Q2": 20,
                "19_Q3": 13,
                "19_Q4": 18,
                "20_Q1": 18,
                "20_Q2": 30,
                "20_Q3": 17,
                "20_Q4": 30,
                "21_Q1": 52,
                "21_Q2": 50,
                "21_Q3": 21,
            }
        ]
    },
    {
        id: "MACGREGOR",
        values: [
            {
                "14_Q1":59,
                "14_Q2": 63,
                "14_Q3": 75,
                "14_Q4": 60,
                "15_Q1": 67,
                "15_Q2": 70,
                "15_Q3": 79,
                "15_Q4": 93,
                "16_Q1": 52,
                "16_Q2": 99,
                "16_Q3": 121,
                "16_Q4": 109,
                "17_Q1": 81,
                "17_Q2": 70,
                "17_Q3": 50,
                "17_Q4": 77,
                "18_Q1": 57,
                "18_Q2": 37,
                "18_Q3": 85,
                "18_Q4": 106,
                "19_Q1": 81,
                "19_Q2": 109,
                "19_Q3": 80,
                "19_Q4": 72,
                "20_Q1": 52,
                "20_Q2": 36,
                "20_Q3": 43,
                "20_Q4": 57,
                "21_Q1": 41,
                "21_Q2": 56,
                "21_Q3": 41,
            }
        ]
    },
    {
        id: "MACQUARIE",
        values: [
            {
                "14_Q1":152,
                "14_Q2": 69,
                "14_Q3": 83,
                "14_Q4": 99,
                "15_Q1": 67,
                "15_Q2": 66,
                "15_Q3": 108,
                "15_Q4": 82,
                "16_Q1": 81,
                "16_Q2": 98,
                "16_Q3": 114,
                "16_Q4": 88,
                "17_Q1": 132,
                "17_Q2": 130,
                "17_Q3": 134,
                "17_Q4": 90,
                "18_Q1": 101,
                "18_Q2": 101,
                "18_Q3": 120,
                "18_Q4": 132,
                "19_Q1": 361,
                "19_Q2": 89,
                "19_Q3": 78,
                "19_Q4": 139,
                "20_Q1": 117,
                "20_Q2": 117,
                "20_Q3": 85,
                "20_Q4": 108,
                "21_Q1": 108,
                "21_Q2": 101,
                "21_Q3": 78,
            }
        ]
    },
    {
        id: "MCKELLAR",
        values: [
            {
                "14_Q1":35,
                "14_Q2": 46,
                "14_Q3": 48,
                "14_Q4": 36,
                "15_Q1": 36,
                "15_Q2": 64,
                "15_Q3": 39,
                "15_Q4": 33,
                "16_Q1": 33,
                "16_Q2": 45,
                "16_Q3": 41,
                "16_Q4": 36,
                "17_Q1": 31,
                "17_Q2": 65,
                "17_Q3": 50,
                "17_Q4": 42,
                "18_Q1": 39,
                "18_Q2": 30,
                "18_Q3": 45,
                "18_Q4": 42,
                "19_Q1": 32,
                "19_Q2": 34,
                "19_Q3": 26,
                "19_Q4": 33,
                "20_Q1": 41,
                "20_Q2": 38,
                "20_Q3": 31,
                "20_Q4": 35,
                "21_Q1": 39,
                "21_Q2": 43,
                "21_Q3": 19,
            }
        ]
    },
    {
        id: "MELBA",
        values: [
            {
                "14_Q1":50,
                "14_Q2": 62,
                "14_Q3": 40,
                "14_Q4": 43,
                "15_Q1": 36,
                "15_Q2": 48,
                "15_Q3": 61,
                "15_Q4": 43,
                "16_Q1": 57,
                "16_Q2": 41,
                "16_Q3": 79,
                "16_Q4": 54,
                "17_Q1": 87,
                "17_Q2": 53,
                "17_Q3": 75,
                "17_Q4": 57,
                "18_Q1": 68,
                "18_Q2": 64,
                "18_Q3": 45,
                "18_Q4": 101,
                "19_Q1": 82,
                "19_Q2": 62,
                "19_Q3": 34,
                "19_Q4": 48,
                "20_Q1": 61,
                "20_Q2": 44,
                "20_Q3": 50,
                "20_Q4": 35,
                "21_Q1": 54,
                "21_Q2": 39,
                "21_Q3": 19,
            }
        ]
    },
    {
        id: "PAGE",
        values: [
            {
                "14_Q1":63,
                "14_Q2": 45,
                "14_Q3": 57,
                "14_Q4": 37,
                "15_Q1": 52,
                "15_Q2": 46,
                "15_Q3": 47,
                "15_Q4": 26,
                "16_Q1": 25,
                "16_Q2": 45,
                "16_Q3": 66,
                "16_Q4": 61,
                "17_Q1": 66,
                "17_Q2": 38,
                "17_Q3": 69,
                "17_Q4": 48,
                "18_Q1": 92,
                "18_Q2": 58,
                "18_Q3": 70,
                "18_Q4": 53,
                "19_Q1": 35,
                "19_Q2": 63,
                "19_Q3": 43,
                "19_Q4": 68,
                "20_Q1": 47,
                "20_Q2": 87,
                "20_Q3": 70,
                "20_Q4": 64,
                "21_Q1": 46,
                "21_Q2": 51,
                "21_Q3": 34,
            }
        ]
    },
    {
        id: "SCULLIN",
        values: [
            {
                "14_Q1":35,
                "14_Q2": 41,
                "14_Q3": 45,
                "14_Q4": 42,
                "15_Q1": 37,
                "15_Q2": 33,
                "15_Q3": 50,
                "15_Q4": 46,
                "16_Q1": 49,
                "16_Q2": 66,
                "16_Q3": 59,
                "16_Q4": 37,
                "17_Q1": 60,
                "17_Q2": 32,
                "17_Q3": 91,
                "17_Q4": 64,
                "18_Q1": 59,
                "18_Q2": 55,
                "18_Q3": 40,
                "18_Q4": 64,
                "19_Q1": 48,
                "19_Q2": 59,
                "19_Q3": 70,
                "19_Q4": 72,
                "20_Q1": 65,
                "20_Q2": 42,
                "20_Q3": 37,
                "20_Q4": 51,
                "21_Q1": 35,
                "21_Q2": 40,
                "21_Q3": 26,
            }
        ]
    },
    {
        id: "SPENCE",
        values: [
            {
                "14_Q1":88,
                "14_Q2": 34,
                "14_Q3": 36,
                "14_Q4": 38,
                "15_Q1": 32,
                "15_Q2": 25,
                "15_Q3": 19,
                "15_Q4": 37,
                "16_Q1": 38,
                "16_Q2": 52,
                "16_Q3": 47,
                "16_Q4": 63,
                "17_Q1": 55,
                "17_Q2": 55,
                "17_Q3": 68,
                "17_Q4": 61,
                "18_Q1": 28,
                "18_Q2": 32,
                "18_Q3": 38,
                "18_Q4": 55,
                "19_Q1": 40,
                "19_Q2": 54,
                "19_Q3": 45,
                "19_Q4": 56,
                "20_Q1": 35,
                "20_Q2": 58,
                "20_Q3": 55,
                "20_Q4": 38,
                "21_Q1": 26,
                "21_Q2": 41,
                "21_Q3": 32,
            }
        ]
    },
    {
        id: "WEETANGERA",
        values: [
            {
                "14_Q1":31,
                "14_Q2": 17,
                "14_Q3": 29,
                "14_Q4": 16,
                "15_Q1": 17,
                "15_Q2": 22,
                "15_Q3": 33,
                "15_Q4": 26,
                "16_Q1": 24,
                "16_Q2": 12,
                "16_Q3": 45,
                "16_Q4": 32,
                "17_Q1": 40,
                "17_Q2": 30,
                "17_Q3": 33,
                "17_Q4": 50,
                "18_Q1": 28,
                "18_Q2": 34,
                "18_Q3": 29,
                "18_Q4": 34,
                "19_Q1": 22,
                "19_Q2": 36,
                "19_Q3": 29,
                "19_Q4": 22,
                "20_Q1": 34,
                "20_Q2": 33,
                "20_Q3": 37,
                "20_Q4": 45,
                "21_Q1": 27,
                "21_Q2": 19,
                "21_Q3": 14,
            }
        ]
    },
    {
        id: "AMAROO",
        values: [
            {
                "14_Q1":48,
                "14_Q2": 37,
                "14_Q3": 38,
                "14_Q4": 37,
                "15_Q1": 30,
                "15_Q2": 37,
                "15_Q3": 30,
                "15_Q4": 75,
                "16_Q1": 60,
                "16_Q2": 87,
                "16_Q3": 85,
                "16_Q4": 76,
                "17_Q1": 46,
                "17_Q2": 38,
                "17_Q3": 62,
                "17_Q4": 76,
                "18_Q1": 77,
                "18_Q2": 61,
                "18_Q3": 36,
                "18_Q4": 61,
                "19_Q1": 49,
                "19_Q2": 46,
                "19_Q3": 63,
                "19_Q4": 85,
                "20_Q1": 78,
                "20_Q2": 63,
                "20_Q3": 62,
                "20_Q4": 67,
                "21_Q1": 53,
                "21_Q2": 68,
                "21_Q3": 47,
            }
        ]
    },
    {
        id: "BONNER",
        values: [
            {
                "14_Q1":65,
                "14_Q2": 52,
                "14_Q3": 40,
                "14_Q4": 46,
                "15_Q1": 30,
                "15_Q2": 53,
                "15_Q3": 31,
                "15_Q4": 43,
                "16_Q1": 44,
                "16_Q2": 39,
                "16_Q3": 55,
                "16_Q4": 55,
                "17_Q1": 49,
                "17_Q2": 38,
                "17_Q3": 100,
                "17_Q4": 71,
                "18_Q1": 58,
                "18_Q2": 34,
                "18_Q3": 51,
                "18_Q4": 34,
                "19_Q1": 41,
                "19_Q2": 59,
                "19_Q3": 44,
                "19_Q4": 36,
                "20_Q1": 58,
                "20_Q2": 38,
                "20_Q3": 42,
                "20_Q4": 49,
                "21_Q1": 60,
                "21_Q2": 39,
                "21_Q3": 37,
            }
        ]
    },
    {
        id: "CASEY",
        values: [
            {
                "14_Q1":43,
                "14_Q2": 55,
                "14_Q3": 60,
                "14_Q4": 71,
                "15_Q1": 49,
                "15_Q2": 62,
                "15_Q3": 57,
                "15_Q4": 67,
                "16_Q1": 60,
                "16_Q2": 64,
                "16_Q3": 129,
                "16_Q4": 95,
                "17_Q1": 86,
                "17_Q2": 54,
                "17_Q3": 68,
                "17_Q4": 92,
                "18_Q1": 45,
                "18_Q2": 78,
                "18_Q3": 71,
                "18_Q4": 75,
                "19_Q1": 73,
                "19_Q2": 102,
                "19_Q3": 53,
                "19_Q4": 80,
                "20_Q1": 86,
                "20_Q2": 65,
                "20_Q3": 73,
                "20_Q4": 67,
                "21_Q1": 73,
                "21_Q2": 79,
                "21_Q3": 45,
            }
        ]
    },
    {
        id: "CRACE",
        values: [
            {
                "14_Q1":37,
                "14_Q2": 50,
                "14_Q3": 38,
                "14_Q4": 32,
                "15_Q1": 49,
                "15_Q2": 41,
                "15_Q3": 44,
                "15_Q4": 121,
                "16_Q1": 39,
                "16_Q2": 62,
                "16_Q3": 60,
                "16_Q4": 49,
                "17_Q1": 42,
                "17_Q2": 60,
                "17_Q3": 45,
                "17_Q4": 21,
                "18_Q1": 38,
                "18_Q2": 32,
                "18_Q3": 34,
                "18_Q4": 35,
                "19_Q1": 37,
                "19_Q2": 48,
                "19_Q3": 51,
                "19_Q4": 58,
                "20_Q1": 45,
                "20_Q2": 39,
                "20_Q3": 52,
                "20_Q4": 71,
                "21_Q1": 43,
                "21_Q2": 86,
                "21_Q3": 36,
            }
        ]
    },
    {
        id: "FORDE",
        values: [
            {
                "14_Q1":23,
                "14_Q2": 26,
                "14_Q3": 19,
                "14_Q4": 35,
                "15_Q1": 15,
                "15_Q2": 62,
                "15_Q3": 21,
                "15_Q4": 30,
                "16_Q1": 48,
                "16_Q2": 27,
                "16_Q3": 34,
                "16_Q4": 28,
                "17_Q1": 27,
                "17_Q2": 22,
                "17_Q3": 43,
                "17_Q4": 54,
                "18_Q1": 41,
                "18_Q2": 32,
                "18_Q3": 78,
                "18_Q4": 15,
                "19_Q1": 51,
                "19_Q2": 46,
                "19_Q3": 26,
                "19_Q4": 35,
                "20_Q1": 30,
                "20_Q2": 29,
                "20_Q3": 28,
                "20_Q4": 48,
                "21_Q1": 18,
                "21_Q2": 28,
                "21_Q3": 16,
            }
        ]
    },
    {
        id: "FRANKLIN",
        values: [
            {
                "14_Q1":94,
                "14_Q2": 93,
                "14_Q3": 114,
                "14_Q4": 86,
                "15_Q1": 112,
                "15_Q2": 108,
                "15_Q3": 105,
                "15_Q4": 135,
                "16_Q1": 132,
                "16_Q2": 124,
                "16_Q3": 194,
                "16_Q4": 141,
                "17_Q1": 105,
                "17_Q2": 123,
                "17_Q3": 143,
                "17_Q4": 123,
                "18_Q1": 98,
                "18_Q2": 123,
                "18_Q3": 80,
                "18_Q4": 93,
                "19_Q1": 106,
                "19_Q2": 62,
                "19_Q3": 166,
                "19_Q4": 180,
                "20_Q1": 145,
                "20_Q2": 142,
                "20_Q3": 99,
                "20_Q4": 142,
                "21_Q1": 84,
                "21_Q2": 128,
                "21_Q3": 84,
            }
        ]
    },
    {
        id: "GUNGAHLIN",
        values: [
            {
                "14_Q1":296,
                "14_Q2": 254,
                "14_Q3": 229,
                "14_Q4": 271,
                "15_Q1": 197,
                "15_Q2": 268,
                "15_Q3": 249,
                "15_Q4": 312,
                "16_Q1": 292,
                "16_Q2": 327,
                "16_Q3": 297,
                "16_Q4": 234,
                "17_Q1": 332,
                "17_Q2": 245,
                "17_Q3": 360,
                "17_Q4": 399,
                "18_Q1": 277,
                "18_Q2": 296,
                "18_Q3": 506,
                "18_Q4": 236,
                "19_Q1": 330,
                "19_Q2": 307,
                "19_Q3": 276,
                "19_Q4": 388,
                "20_Q1": 348,
                "20_Q2": 274,
                "20_Q3": 268,
                "20_Q4": 236,
                "21_Q1": 233,
                "21_Q2": 315,
                "21_Q3": 210,
            }
        ]
    },
    {
        id: "HALL",
        values: [
            {
                "14_Q1":7,
                "14_Q2": 11,
                "14_Q3": 22,
                "14_Q4": 17,
                "15_Q1": 10,
                "15_Q2": 11,
                "15_Q3": 2,
                "15_Q4": 4,
                "16_Q1": 6,
                "16_Q2": 7,
                "16_Q3": 0,
                "16_Q4": 3,
                "17_Q1": 14,
                "17_Q2": 15,
                "17_Q3": 26,
                "17_Q4": 14,
                "18_Q1": 7,
                "18_Q2": 12,
                "18_Q3": 6,
                "18_Q4": 13,
                "19_Q1": 14,
                "19_Q2": 30,
                "19_Q3": 19,
                "19_Q4": 21,
                "20_Q1": 19,
                "20_Q2": 49,
                "20_Q3": 13,
                "20_Q4": 15,
                "21_Q1": 12,
                "21_Q2": 18,
                "21_Q3": 23,
            }
        ]
    },
    {
        id: "HARRISON",
        values: [
            {
                "14_Q1":102,
                "14_Q2": 97,
                "14_Q3": 67,
                "14_Q4": 80,
                "15_Q1": 66,
                "15_Q2": 120,
                "15_Q3": 85,
                "15_Q4": 124,
                "16_Q1": 76,
                "16_Q2": 105,
                "16_Q3": 116,
                "16_Q4": 93,
                "17_Q1": 106,
                "17_Q2": 190,
                "17_Q3": 99,
                "17_Q4": 143,
                "18_Q1": 98,
                "18_Q2": 99,
                "18_Q3": 64,
                "18_Q4": 62,
                "19_Q1": 110,
                "19_Q2": 83,
                "19_Q3": 64,
                "19_Q4": 73,
                "20_Q1": 54,
                "20_Q2": 63,
                "20_Q3": 100,
                "20_Q4": 114,
                "21_Q1": 93,
                "21_Q2": 100,
                "21_Q3": 60,
            }
        ]
    },
    {
        id: "JACKA",
        values: [
            {
                "14_Q1":1,
                "14_Q2": 5,
                "14_Q3": 13,
                "14_Q4": 4,
                "15_Q1": 21,
                "15_Q2": 13,
                "15_Q3": 2,
                "15_Q4": 5,
                "16_Q1": 3,
                "16_Q2": 4,
                "16_Q3": 8,
                "16_Q4": 6,
                "17_Q1": 16,
                "17_Q2": 3,
                "17_Q3": 9,
                "17_Q4": 7,
                "18_Q1": 10,
                "18_Q2": 4,
                "18_Q3": 12,
                "18_Q4": 1,
                "19_Q1": 5,
                "19_Q2": 11,
                "19_Q3": 7,
                "19_Q4": 8,
                "20_Q1": 8,
                "20_Q2": 5,
                "20_Q3": 11,
                "20_Q4": 4,
                "21_Q1": 6,
                "21_Q2": 11,
                "21_Q3": 8,
            }
        ]
    },
    {
        id: "KENNY",
        values: [
            {
                "14_Q1":3,
                "14_Q2": 23,
                "14_Q3": 10,
                "14_Q4": 6,
                "15_Q1": 10,
                "15_Q2": 10,
                "15_Q3": 6,
                "15_Q4": 5,
                "16_Q1": 12,
                "16_Q2": 7,
                "16_Q3": 1,
                "16_Q4": 6,
                "17_Q1": 4,
                "17_Q2": 6,
                "17_Q3": 12,
                "17_Q4": 12,
                "18_Q1": 2,
                "18_Q2": 9,
                "18_Q3": 1,
                "18_Q4": 3,
                "19_Q1": 9,
                "19_Q2": 25,
                "19_Q3": 27,
                "19_Q4": 17,
                "20_Q1": 16,
                "20_Q2": 22,
                "20_Q3": 7,
                "20_Q4": 15,
                "21_Q1": 10,
                "21_Q2": 14,
                "21_Q3": 15,
            }
        ]
    },
    {
        id: "KINLYSIDE",
        values: [
            {
                "14_Q1":0,
                "14_Q2": 0,
                "14_Q3": 0,
                "14_Q4": 0,
                "15_Q1": 2,
                "15_Q2": 6,
                "15_Q3": 2,
                "15_Q4": 0,
                "16_Q1": 0,
                "16_Q2": 0,
                "16_Q3": 0,
                "16_Q4": 0,
                "17_Q1": 0,
                "17_Q2": 0,
                "17_Q3": 0,
                "17_Q4": 0,
                "18_Q1": 0,
                "18_Q2": 0,
                "18_Q3": 0,
                "18_Q4": 0,
                "19_Q1": 0,
                "19_Q2": 0,
                "19_Q3": 0,
                "19_Q4": 0,
                "20_Q1": 0,
                "20_Q2": 0,
                "20_Q3": 0,
                "20_Q4": 0,
                "21_Q1": 0,
                "21_Q2": 0,
                "21_Q3": 0,
            }
        ]
    },
    {
        id: "MITCHELL",
        values: [
            {
                "14_Q1":130,
                "14_Q2": 105,
                "14_Q3": 124,
                "14_Q4": 121,
                "15_Q1": 151,
                "15_Q2": 131,
                "15_Q3": 116,
                "15_Q4": 109,
                "16_Q1": 169,
                "16_Q2": 138,
                "16_Q3": 167,
                "16_Q4": 136,
                "17_Q1": 143,
                "17_Q2": 202,
                "17_Q3": 175,
                "17_Q4": 103,
                "18_Q1": 118,
                "18_Q2": 92,
                "18_Q3": 92,
                "18_Q4": 79,
                "19_Q1": 135,
                "19_Q2": 120,
                "19_Q3": 104,
                "19_Q4": 86,
                "20_Q1": 86,
                "20_Q2": 100,
                "20_Q3": 114,
                "20_Q4": 103,
                "21_Q1": 107,
                "21_Q2": 125,
                "21_Q3": 65,
            }
        ]
    },
    {
        id: "MONCRIEFF",
        values: [
            {
                "14_Q1":0,
                "14_Q2": 0,
                "14_Q3": 5,
                "14_Q4": 7,
                "15_Q1": 5,
                "15_Q2": 10,
                "15_Q3": 9,
                "15_Q4": 17,
                "16_Q1": 15,
                "16_Q2": 26,
                "16_Q3": 44,
                "16_Q4": 50,
                "17_Q1": 32,
                "17_Q2": 70,
                "17_Q3": 82,
                "17_Q4": 67,
                "18_Q1": 72,
                "18_Q2": 54,
                "18_Q3": 54,
                "18_Q4": 70,
                "19_Q1": 63,
                "19_Q2": 39,
                "19_Q3": 63,
                "19_Q4": 52,
                "20_Q1": 44,
                "20_Q2": 69,
                "20_Q3": 48,
                "20_Q4": 55,
                "21_Q1": 89,
                "21_Q2": 98,
                "21_Q3": 69,
            }
        ]
    },
    {
        id: "NGUNNAWAL",
        values: [
            {
                "14_Q1":98,
                "14_Q2": 97,
                "14_Q3": 71,
                "14_Q4": 81,
                "15_Q1": 62,
                "15_Q2": 86,
                "15_Q3": 85,
                "15_Q4": 118,
                "16_Q1": 111,
                "16_Q2": 116,
                "16_Q3": 102,
                "16_Q4": 94,
                "17_Q1": 105,
                "17_Q2": 108,
                "17_Q3": 106,
                "17_Q4": 127,
                "18_Q1": 96,
                "18_Q2": 131,
                "18_Q3": 103,
                "18_Q4": 119,
                "19_Q1": 115,
                "19_Q2": 106,
                "19_Q3": 85,
                "19_Q4": 105,
                "20_Q1": 124,
                "20_Q2": 124,
                "20_Q3": 123,
                "20_Q4": 89,
                "21_Q1": 89,
                "21_Q2": 88,
                "21_Q3": 85,
            }
        ]
    },
    {
        id: "NICHOLLS",
        values: [
            {
                "14_Q1":87,
                "14_Q2": 110,
                "14_Q3": 85,
                "14_Q4": 89,
                "15_Q1": 80,
                "15_Q2": 80,
                "15_Q3": 99,
                "15_Q4": 100,
                "16_Q1": 101,
                "16_Q2": 93,
                "16_Q3": 91,
                "16_Q4": 85,
                "17_Q1": 91,
                "17_Q2": 104,
                "17_Q3": 89,
                "17_Q4": 97,
                "18_Q1": 62,
                "18_Q2": 60,
                "18_Q3": 62,
                "18_Q4": 76,
                "19_Q1": 68,
                "19_Q2": 82,
                "19_Q3": 76,
                "19_Q4": 89,
                "20_Q1": 72,
                "20_Q2": 95,
                "20_Q3": 84,
                "20_Q4": 47,
                "21_Q1": 56,
                "21_Q2": 67,
                "21_Q3": 42,
            }
        ]
    },
    {
        id: "PALMERSTON",
        values: [
            {
                "14_Q1":55,
                "14_Q2": 61,
                "14_Q3": 55,
                "14_Q4": 68,
                "15_Q1": 83,
                "15_Q2": 70,
                "15_Q3": 48,
                "15_Q4": 72,
                "16_Q1": 67,
                "16_Q2": 80,
                "16_Q3": 83,
                "16_Q4": 94,
                "17_Q1": 94,
                "17_Q2": 57,
                "17_Q3": 117,
                "17_Q4": 126,
                "18_Q1": 48,
                "18_Q2": 66,
                "18_Q3": 54,
                "18_Q4": 73,
                "19_Q1": 87,
                "19_Q2": 92,
                "19_Q3": 88,
                "19_Q4": 132,
                "20_Q1": 130,
                "20_Q2": 70,
                "20_Q3": 60,
                "20_Q4": 98,
                "21_Q1": 67,
                "21_Q2": 61,
                "21_Q3": 55,
            }
        ]
    },
    {
        id: "TAYLOR",
        values: [
            {
                "14_Q1":0,
                "14_Q2": 0,
                "14_Q3": 2,
                "14_Q4": 0,
                "15_Q1": 0,
                "15_Q2": 1,
                "15_Q3": 0,
                "15_Q4": 3,
                "16_Q1": 2,
                "16_Q2": 2,
                "16_Q3": 8,
                "16_Q4": 2,
                "17_Q1": 0,
                "17_Q2": 16,
                "17_Q3": 1,
                "17_Q4": 1,
                "18_Q1": 0,
                "18_Q2": 6,
                "18_Q3": 7,
                "18_Q4": 7,
                "19_Q1": 13,
                "19_Q2": 18,
                "19_Q3": 45,
                "19_Q4": 29,
                "20_Q1": 47,
                "20_Q2": 33,
                "20_Q3": 55,
                "20_Q4": 33,
                "21_Q1": 53,
                "21_Q2": 35,
                "21_Q3": 50,
            }
        ]
    },
    {
        id: "THROSBY",
        values: [
            {
                "14_Q1":0,
                "14_Q2": 1,
                "14_Q3": 2,
                "14_Q4": 1,
                "15_Q1": 4,
                "15_Q2": 0,
                "15_Q3": 0,
                "15_Q4": 0,
                "16_Q1": 7,
                "16_Q2": 5,
                "16_Q3": 3,
                "16_Q4": 8,
                "17_Q1": 11,
                "17_Q2": 4,
                "17_Q3": 18,
                "17_Q4": 7,
                "18_Q1": 10,
                "18_Q2": 8,
                "18_Q3": 8,
                "18_Q4": 12,
                "19_Q1": 13,
                "19_Q2": 18,
                "19_Q3": 18,
                "19_Q4": 40,
                "20_Q1": 26,
                "20_Q2": 37,
                "20_Q3": 36,
                "20_Q4": 61,
                "21_Q1": 21,
                "21_Q2": 46,
                "21_Q3": 14,
            }
        ]
    },
    {
        id: "ACTON",
        values: [
            {
                "14_Q1":141,
                "14_Q2": 146,
                "14_Q3": 166,
                "14_Q4": 122,
                "15_Q1": 113,
                "15_Q2": 144,
                "15_Q3": 115,
                "15_Q4": 177,
                "16_Q1": 152,
                "16_Q2": 158,
                "16_Q3": 174,
                "16_Q4": 154,
                "17_Q1": 145,
                "17_Q2": 185,
                "17_Q3": 144,
                "17_Q4": 171,
                "18_Q1": 161,
                "18_Q2": 157,
                "18_Q3": 169,
                "18_Q4": 178,
                "19_Q1": 99,
                "19_Q2": 128,
                "19_Q3": 160,
                "19_Q4": 161,
                "20_Q1": 152,
                "20_Q2": 145,
                "20_Q3": 118,
                "20_Q4": 141,
                "21_Q1": 122,
                "21_Q2": 196,
                "21_Q3": 134,
            }
        ]
    },
    {
        id: "AINSLIE",
        values: [
            {
                "14_Q1":85,
                "14_Q2": 106,
                "14_Q3": 133,
                "14_Q4": 125,
                "15_Q1": 122,
                "15_Q2": 86,
                "15_Q3": 132,
                "15_Q4": 125,
                "16_Q1": 85,
                "16_Q2": 91,
                "16_Q3": 97,
                "16_Q4": 103,
                "17_Q1": 138,
                "17_Q2": 119,
                "17_Q3": 229,
                "17_Q4": 131,
                "18_Q1": 116,
                "18_Q2": 129,
                "18_Q3": 127,
                "18_Q4": 129,
                "19_Q1": 159,
                "19_Q2": 115,
                "19_Q3": 135,
                "19_Q4": 152,
                "20_Q1": 90,
                "20_Q2": 79,
                "20_Q3": 178,
                "20_Q4": 118,
                "21_Q1": 97,
                "21_Q2": 78,
                "21_Q3": 116,
            }
        ]
    },
    {
        id: "BLACK MOUNTAIN",
        values: [
            {
                "14_Q1":0,
                "14_Q2": 0,
                "14_Q3": 0,
                "14_Q4": 0,
                "15_Q1": 0,
                "15_Q2": 0,
                "15_Q3": 0,
                "15_Q4": 0,
                "16_Q1": 0,
                "16_Q2": 0,
                "16_Q3": 0,
                "16_Q4": 0,
                "17_Q1": 0,
                "17_Q2": 0,
                "17_Q3": 0,
                "17_Q4": 0,
                "18_Q1": 0,
                "18_Q2": 0,
                "18_Q3": 0,
                "18_Q4": 0,
                "19_Q1": 0,
                "19_Q2": 0,
                "19_Q3": 0,
                "19_Q4": 0,
                "20_Q1": 0,
                "20_Q2": 0,
                "20_Q3": 0,
                "20_Q4": 0,
                "21_Q1": 0,
                "21_Q2": 0,
                "21_Q3": 0,
            }
        ]
    },
    {
        id: "BRADDON",
        values: [
            {
                "14_Q1":254,
                "14_Q2": 287,
                "14_Q3": 366,
                "14_Q4": 383,
                "15_Q1": 328,
                "15_Q2": 352,
                "15_Q3": 362,
                "15_Q4": 306,
                "16_Q1": 402,
                "16_Q2": 376,
                "16_Q3": 273,
                "16_Q4": 208,
                "17_Q1": 257,
                "17_Q2": 241,
                "17_Q3": 221,
                "17_Q4": 246,
                "18_Q1": 264,
                "18_Q2": 251,
                "18_Q3": 288,
                "18_Q4": 271,
                "19_Q1": 283,
                "19_Q2": 239,
                "19_Q3": 195,
                "19_Q4": 192,
                "20_Q1": 278,
                "20_Q2": 184,
                "20_Q3": 258,
                "20_Q4": 326,
                "21_Q1": 277,
                "21_Q2": 282,
                "21_Q3": 309,
            }
        ]
    },
    {
        id: "CAMPBELL",
        values: [
            {
                "14_Q1":71,
                "14_Q2": 70,
                "14_Q3": 108,
                "14_Q4": 107,
                "15_Q1": 49,
                "15_Q2": 73,
                "15_Q3": 83,
                "15_Q4": 50,
                "16_Q1": 47,
                "16_Q2": 71,
                "16_Q3": 81,
                "16_Q4": 55,
                "17_Q1": 80,
                "17_Q2": 60,
                "17_Q3": 60,
                "17_Q4": 95,
                "18_Q1": 75,
                "18_Q2": 101,
                "18_Q3": 63,
                "18_Q4": 74,
                "19_Q1": 75,
                "19_Q2": 69,
                "19_Q3": 49,
                "19_Q4": 60,
                "20_Q1": 65,
                "20_Q2": 50,
                "20_Q3": 82,
                "20_Q4": 68,
                "21_Q1": 74,
                "21_Q2": 62,
                "21_Q3": 42,
            }
        ]
    },
    {
        id: "CITY",
        values: [
            {
                "14_Q1":1383,
                "14_Q2": 936,
                "14_Q3": 943,
                "14_Q4": 722,
                "15_Q1": 720,
                "15_Q2": 997,
                "15_Q3": 887,
                "15_Q4": 833,
                "16_Q1": 864,
                "16_Q2": 838,
                "16_Q3": 910,
                "16_Q4": 911,
                "17_Q1": 1006,
                "17_Q2": 979,
                "17_Q3": 865,
                "17_Q4": 960,
                "18_Q1": 906,
                "18_Q2": 787,
                "18_Q3": 837,
                "18_Q4": 862,
                "19_Q1": 831,
                "19_Q2": 728,
                "19_Q3": 692,
                "19_Q4": 827,
                "20_Q1": 794,
                "20_Q2": 360,
                "20_Q3": 394,
                "20_Q4": 594,
                "21_Q1": 585,
                "21_Q2": 623,
                "21_Q3": 372,
            }
        ]
    },
    {
        id: "DICKSON",
        values: [
            {
                "14_Q1":242,
                "14_Q2": 283,
                "14_Q3": 245,
                "14_Q4": 239,
                "15_Q1": 210,
                "15_Q2": 212,
                "15_Q3": 190,
                "15_Q4": 229,
                "16_Q1": 175,
                "16_Q2": 160,
                "16_Q3": 203,
                "16_Q4": 196,
                "17_Q1": 229,
                "17_Q2": 208,
                "17_Q3": 209,
                "17_Q4": 185,
                "18_Q1": 170,
                "18_Q2": 156,
                "18_Q3": 141,
                "18_Q4": 134,
                "19_Q1": 207,
                "19_Q2": 203,
                "19_Q3": 148,
                "19_Q4": 145,
                "20_Q1": 170,
                "20_Q2": 164,
                "20_Q3": 120,
                "20_Q4": 171,
                "21_Q1": 120,
                "21_Q2": 194,
                "21_Q3": 227,
            }
        ]
    },
    {
        id: "DOWNER",
        values: [
            {
                "14_Q1":62,
                "14_Q2": 46,
                "14_Q3": 29,
                "14_Q4": 42,
                "15_Q1": 51,
                "15_Q2": 39,
                "15_Q3": 36,
                "15_Q4": 39,
                "16_Q1": 35,
                "16_Q2": 50,
                "16_Q3": 37,
                "16_Q4": 51,
                "17_Q1": 43,
                "17_Q2": 45,
                "17_Q3": 45,
                "17_Q4": 50,
                "18_Q1": 25,
                "18_Q2": 45,
                "18_Q3": 42,
                "18_Q4": 39,
                "19_Q1": 74,
                "19_Q2": 54,
                "19_Q3": 52,
                "19_Q4": 51,
                "20_Q1": 75,
                "20_Q2": 75,
                "20_Q3": 34,
                "20_Q4": 56,
                "21_Q1": 56,
                "21_Q2": 32,
                "21_Q3": 54,
            }
        ]
    },
    {
        id: "DUNTROON",
        values: [
            {
                "14_Q1":1,
                "14_Q2": 2,
                "14_Q3": 5,
                "14_Q4": 0,
                "15_Q1": 3,
                "15_Q2": 7,
                "15_Q3": 1,
                "15_Q4": 4,
                "16_Q1": 16,
                "16_Q2": 24,
                "16_Q3": 10,
                "16_Q4": 9,
                "17_Q1": 6,
                "17_Q2": 1,
                "17_Q3": 6,
                "17_Q4": 2,
                "18_Q1": 3,
                "18_Q2": 3,
                "18_Q3": 3,
                "18_Q4": 2,
                "19_Q1": 10,
                "19_Q2": 1,
                "19_Q3": 0,
                "19_Q4": 4,
                "20_Q1": 1,
                "20_Q2": 5,
                "20_Q3": 3,
                "20_Q4": 0,
                "21_Q1": 1,
                "21_Q2": 7,
                "21_Q3": 2,
            }
        ]
    },
    {
        id: "HACKETT",
        values: [
            {
                "14_Q1":25,
                "14_Q2": 26,
                "14_Q3": 24,
                "14_Q4": 35,
                "15_Q1": 17,
                "15_Q2": 11,
                "15_Q3": 30,
                "15_Q4": 45,
                "16_Q1": 95,
                "16_Q2": 26,
                "16_Q3": 18,
                "16_Q4": 39,
                "17_Q1": 81,
                "17_Q2": 23,
                "17_Q3": 31,
                "17_Q4": 20,
                "18_Q1": 68,
                "18_Q2": 23,
                "18_Q3": 34,
                "18_Q4": 55,
                "19_Q1": 36,
                "19_Q2": 48,
                "19_Q3": 48,
                "19_Q4": 21,
                "20_Q1": 69,
                "20_Q2": 36,
                "20_Q3": 46,
                "20_Q4": 51,
                "21_Q1": 41,
                "21_Q2": 24,
                "21_Q3": 38,
            }
        ]
    },
    {
        id: "LYNEHAM",
        values: [
            {
                "14_Q1":254,
                "14_Q2": 248,
                "14_Q3": 234,
                "14_Q4": 282,
                "15_Q1": 185,
                "15_Q2": 152,
                "15_Q3": 187,
                "15_Q4": 208,
                "16_Q1": 162,
                "16_Q2": 190,
                "16_Q3": 189,
                "16_Q4": 195,
                "17_Q1": 244,
                "17_Q2": 180,
                "17_Q3": 213,
                "17_Q4": 168,
                "18_Q1": 231,
                "18_Q2": 199,
                "18_Q3": 275,
                "18_Q4": 230,
                "19_Q1": 289,
                "19_Q2": 214,
                "19_Q3": 129,
                "19_Q4": 250,
                "20_Q1": 149,
                "20_Q2": 189,
                "20_Q3": 155,
                "20_Q4": 217,
                "21_Q1": 170,
                "21_Q2": 150,
                "21_Q3": 114,
            }
        ]
    },
    {
        id: "MAJURA",
        values: [
            {
                "14_Q1":83,
                "14_Q2": 35,
                "14_Q3": 121,
                "14_Q4": 72,
                "15_Q1": 150,
                "15_Q2": 54,
                "15_Q3": 101,
                "15_Q4": 85,
                "16_Q1": 58,
                "16_Q2": 46,
                "16_Q3": 95,
                "16_Q4": 77,
                "17_Q1": 40,
                "17_Q2": 73,
                "17_Q3": 99,
                "17_Q4": 133,
                "18_Q1": 108,
                "18_Q2": 82,
                "18_Q3": 91,
                "18_Q4": 83,
                "19_Q1": 93,
                "19_Q2": 80,
                "19_Q3": 72,
                "19_Q4": 90,
                "20_Q1": 89,
                "20_Q2": 140,
                "20_Q3": 129,
                "20_Q4": 101,
                "21_Q1": 53,
                "21_Q2": 133,
                "21_Q3": 72,
            }
        ]
    },
    {
        id: "O'CONNOR",
        values: [
            {
                "14_Q1":154,
                "14_Q2": 135,
                "14_Q3": 111,
                "14_Q4": 91,
                "15_Q1": 114,
                "15_Q2": 102,
                "15_Q3": 109,
                "15_Q4": 101,
                "16_Q1": 77,
                "16_Q2": 99,
                "16_Q3": 103,
                "16_Q4": 99,
                "17_Q1": 156,
                "17_Q2": 153,
                "17_Q3": 115,
                "17_Q4": 123,
                "18_Q1": 132,
                "18_Q2": 105,
                "18_Q3": 90,
                "18_Q4": 102,
                "19_Q1": 143,
                "19_Q2": 147,
                "19_Q3": 114,
                "19_Q4": 114,
                "20_Q1": 144,
                "20_Q2": 132,
                "20_Q3": 102,
                "20_Q4": 121,
                "21_Q1": 101,
                "21_Q2": 122,
                "21_Q3": 84,
            }
        ]
    },
    {
        id: "PARKES",
        values: [
            {
                "14_Q1":75,
                "14_Q2": 59,
                "14_Q3": 50,
                "14_Q4": 64,
                "15_Q1": 47,
                "15_Q2": 59,
                "15_Q3": 54,
                "15_Q4": 72,
                "16_Q1": 50,
                "16_Q2": 51,
                "16_Q3": 62,
                "16_Q4": 42,
                "17_Q1": 46,
                "17_Q2": 41,
                "17_Q3": 29,
                "17_Q4": 52,
                "18_Q1": 36,
                "18_Q2": 20,
                "18_Q3": 36,
                "18_Q4": 41,
                "19_Q1": 30,
                "19_Q2": 23,
                "19_Q3": 21,
                "19_Q4": 38,
                "20_Q1": 29,
                "20_Q2": 11,
                "20_Q3": 17,
                "20_Q4": 27,
                "21_Q1": 25,
                "21_Q2": 30,
                "21_Q3": 37,
            }
        ]
    },
    {
        id: "REID",
        values: [
            {
                "14_Q1":103,
                "14_Q2": 78,
                "14_Q3": 104,
                "14_Q4": 102,
                "15_Q1": 128,
                "15_Q2": 115,
                "15_Q3": 92,
                "15_Q4": 101,
                "16_Q1": 116,
                "16_Q2": 113,
                "16_Q3": 105,
                "16_Q4": 150,
                "17_Q1": 173,
                "17_Q2": 130,
                "17_Q3": 147,
                "17_Q4": 114,
                "18_Q1": 87,
                "18_Q2": 79,
                "18_Q3": 79,
                "18_Q4": 76,
                "19_Q1": 137,
                "19_Q2": 84,
                "19_Q3": 70,
                "19_Q4": 126,
                "20_Q1": 74,
                "20_Q2": 51,
                "20_Q3": 77,
                "20_Q4": 74,
                "21_Q1": 71,
                "21_Q2": 69,
                "21_Q3": 75,
            }
        ]
    },
    {
        id: "RUSSELL",
        values: [
            {
                "14_Q1":33,
                "14_Q2": 27,
                "14_Q3": 27,
                "14_Q4": 25,
                "15_Q1": 34,
                "15_Q2": 12,
                "15_Q3": 35,
                "15_Q4": 19,
                "16_Q1": 30,
                "16_Q2": 27,
                "16_Q3": 34,
                "16_Q4": 45,
                "17_Q1": 24,
                "17_Q2": 36,
                "17_Q3": 34,
                "17_Q4": 44,
                "18_Q1": 52,
                "18_Q2": 33,
                "18_Q3": 37,
                "18_Q4": 24,
                "19_Q1": 33,
                "19_Q2": 23,
                "19_Q3": 37,
                "19_Q4": 17,
                "20_Q1": 14,
                "20_Q2": 13,
                "20_Q3": 23,
                "20_Q4": 41,
                "21_Q1": 19,
                "21_Q2": 31,
                "21_Q3": 14,
            }
        ]
    },
    {
        id: "TURNER",
        values: [
            {
                "14_Q1":122,
                "14_Q2": 103,
                "14_Q3": 123,
                "14_Q4": 97,
                "15_Q1": 143,
                "15_Q2": 149,
                "15_Q3": 130,
                "15_Q4": 114,
                "16_Q1": 91,
                "16_Q2": 115,
                "16_Q3": 106,
                "16_Q4": 92,
                "17_Q1": 103,
                "17_Q2": 103,
                "17_Q3": 96,
                "17_Q4": 106,
                "18_Q1": 128,
                "18_Q2": 84,
                "18_Q3": 106,
                "18_Q4": 108,
                "19_Q1": 129,
                "19_Q2": 170,
                "19_Q3": 143,
                "19_Q4": 126,
                "20_Q1": 161,
                "20_Q2": 65,
                "20_Q3": 74,
                "20_Q4": 104,
                "21_Q1": 82,
                "21_Q2": 173,
                "21_Q3": 139,
            }
        ]
    },
    {
        id: "WATSON",
        values: [
            {
                "14_Q1":84,
                "14_Q2": 82,
                "14_Q3": 83,
                "14_Q4": 70,
                "15_Q1": 80,
                "15_Q2": 95,
                "15_Q3": 55,
                "15_Q4": 81,
                "16_Q1": 77,
                "16_Q2": 63,
                "16_Q3": 97,
                "16_Q4": 92,
                "17_Q1": 150,
                "17_Q2": 116,
                "17_Q3": 85,
                "17_Q4": 63,
                "18_Q1": 71,
                "18_Q2": 57,
                "18_Q3": 89,
                "18_Q4": 108,
                "19_Q1": 104,
                "19_Q2": 112,
                "19_Q3": 92,
                "19_Q4": 97,
                "20_Q1": 154,
                "20_Q2": 96,
                "20_Q3": 96,
                "20_Q4": 71,
                "21_Q1": 78,
                "21_Q2": 78,
                "21_Q3": 106,
            }
        ]
    },
    {
        id: "BARTON",
        values: [
            {
                "14_Q1":158,
                "14_Q2": 183,
                "14_Q3": 138,
                "14_Q4": 135,
                "15_Q1": 100,
                "15_Q2": 161,
                "15_Q3": 165,
                "15_Q4": 133,
                "16_Q1": 89,
                "16_Q2": 125,
                "16_Q3": 163,
                "16_Q4": 141,
                "17_Q1": 155,
                "17_Q2": 168,
                "17_Q3": 166,
                "17_Q4": 168,
                "18_Q1": 132,
                "18_Q2": 131,
                "18_Q3": 119,
                "18_Q4": 116,
                "19_Q1": 325,
                "19_Q2": 176,
                "19_Q3": 156,
                "19_Q4": 222,
                "20_Q1": 221,
                "20_Q2": 82,
                "20_Q3": 118,
                "20_Q4": 132,
                "21_Q1": 104,
                "21_Q2": 141,
                "21_Q3": 75,
            }
        ]
    },
    {
        id: "BEARD",
        values: [
            {
                "14_Q1":8,
                "14_Q2": 3,
                "14_Q3": 4,
                "14_Q4": 0,
                "15_Q1": 0,
                "15_Q2": 2,
                "15_Q3": 3,
                "15_Q4": 7,
                "16_Q1": 0,
                "16_Q2": 3,
                "16_Q3": 0,
                "16_Q4": 10,
                "17_Q1": 8,
                "17_Q2": 7,
                "17_Q3": 5,
                "17_Q4": 4,
                "18_Q1": 4,
                "18_Q2": 4,
                "18_Q3": 3,
                "18_Q4": 7,
                "19_Q1": 6,
                "19_Q2": 6,
                "19_Q3": 4,
                "19_Q4": 9,
                "20_Q1": 19,
                "20_Q2": 10,
                "20_Q3": 7,
                "20_Q4": 8,
                "21_Q1": 4,
                "21_Q2": 12,
                "21_Q3": 9,
            }
        ]
    },
    {
        id: "CAPITAL HILL",
        values: [
            {
                "14_Q1":6,
                "14_Q2": 5,
                "14_Q3": 3,
                "14_Q4": 8,
                "15_Q1": 12,
                "15_Q2": 3,
                "15_Q3": 6,
                "15_Q4": 10,
                "16_Q1": 12,
                "16_Q2": 10,
                "16_Q3": 5,
                "16_Q4": 16,
                "17_Q1": 3,
                "17_Q2": 5,
                "17_Q3": 12,
                "17_Q4": 3,
                "18_Q1": 3,
                "18_Q2": 11,
                "18_Q3": 4,
                "18_Q4": 7,
                "19_Q1": 4,
                "19_Q2": 2,
                "19_Q3": 1,
                "19_Q4": 6,
                "20_Q1": 7,
                "20_Q2": 3,
                "20_Q3": 2,
                "20_Q4": 2,
                "21_Q1": 17,
                "21_Q2": 19,
                "21_Q3": 9,
            }
        ]
    },
    {
        id: "DEAKIN",
        values: [
            {
                "14_Q1":93,
                "14_Q2": 83,
                "14_Q3": 91,
                "14_Q4": 81,
                "15_Q1": 69,
                "15_Q2": 128,
                "15_Q3": 97,
                "15_Q4": 81,
                "16_Q1": 81,
                "16_Q2": 91,
                "16_Q3": 114,
                "16_Q4": 81,
                "17_Q1": 112,
                "17_Q2": 76,
                "17_Q3": 92,
                "17_Q4": 102,
                "18_Q1": 85,
                "18_Q2": 80,
                "18_Q3": 67,
                "18_Q4": 94,
                "19_Q1": 57,
                "19_Q2": 83,
                "19_Q3": 66,
                "19_Q4": 88,
                "20_Q1": 101,
                "20_Q2": 34,
                "20_Q3": 76,
                "20_Q4": 45,
                "21_Q1": 41,
                "21_Q2": 44,
                "21_Q3": 79,
            }
        ]
    },
    {
        id: "FORREST",
        values: [
            {
                "14_Q1":43,
                "14_Q2": 32,
                "14_Q3": 58,
                "14_Q4": 40,
                "15_Q1": 21,
                "15_Q2": 47,
                "15_Q3": 39,
                "15_Q4": 45,
                "16_Q1": 19,
                "16_Q2": 25,
                "16_Q3": 32,
                "16_Q4": 23,
                "17_Q1": 33,
                "17_Q2": 31,
                "17_Q3": 42,
                "17_Q4": 38,
                "18_Q1": 28,
                "18_Q2": 36,
                "18_Q3": 52,
                "18_Q4": 31,
                "19_Q1": 40,
                "19_Q2": 39,
                "19_Q3": 29,
                "19_Q4": 22,
                "20_Q1": 54,
                "20_Q2": 32,
                "20_Q3": 19,
                "20_Q4": 28,
                "21_Q1": 49,
                "21_Q2": 41,
                "21_Q3": 31,
            }
        ]
    },
    {
        id: "FYSHWICK",
        values: [
            {
                "14_Q1":259,
                "14_Q2": 181,
                "14_Q3": 228,
                "14_Q4": 204,
                "15_Q1": 263,
                "15_Q2": 232,
                "15_Q3": 316,
                "15_Q4": 286,
                "16_Q1": 251,
                "16_Q2": 260,
                "16_Q3": 270,
                "16_Q4": 287,
                "17_Q1": 261,
                "17_Q2": 232,
                "17_Q3": 293,
                "17_Q4": 201,
                "18_Q1": 292,
                "18_Q2": 240,
                "18_Q3": 299,
                "18_Q4": 326,
                "19_Q1": 232,
                "19_Q2": 133,
                "19_Q3": 181,
                "19_Q4": 204,
                "20_Q1": 218,
                "20_Q2": 219,
                "20_Q3": 260,
                "20_Q4": 196,
                "21_Q1": 215,
                "21_Q2": 253,
                "21_Q3": 160,
            }
        ]
    },
    {
        id: "GRIFFITH",
        values: [
            {
                "14_Q1":175,
                "14_Q2": 152,
                "14_Q3": 152,
                "14_Q4": 223,
                "15_Q1": 126,
                "15_Q2": 182,
                "15_Q3": 180,
                "15_Q4": 199,
                "16_Q1": 182,
                "16_Q2": 256,
                "16_Q3": 209,
                "16_Q4": 231,
                "17_Q1": 201,
                "17_Q2": 240,
                "17_Q3": 169,
                "17_Q4": 255,
                "18_Q1": 201,
                "18_Q2": 181,
                "18_Q3": 173,
                "18_Q4": 110,
                "19_Q1": 118,
                "19_Q2": 109,
                "19_Q3": 89,
                "19_Q4": 97,
                "20_Q1": 112,
                "20_Q2": 86,
                "20_Q3": 111,
                "20_Q4": 100,
                "21_Q1": 118,
                "21_Q2": 189,
                "21_Q3": 100,
            }
        ]
    },
    {
        id: "HARMAN",
        values: [
            {
                "14_Q1":5,
                "14_Q2": 10,
                "14_Q3": 4,
                "14_Q4": 2,
                "15_Q1": 6,
                "15_Q2": 0,
                "15_Q3": 0,
                "15_Q4": 0,
                "16_Q1": 0,
                "16_Q2": 0,
                "16_Q3": 1,
                "16_Q4": 1,
                "17_Q1": 0,
                "17_Q2": 2,
                "17_Q3": 4,
                "17_Q4": 2,
                "18_Q1": 2,
                "18_Q2": 5,
                "18_Q3": 5,
                "18_Q4": 2,
                "19_Q1": 0,
                "19_Q2": 2,
                "19_Q3": 0,
                "19_Q4": 1,
                "20_Q1": 3,
                "20_Q2": 3,
                "20_Q3": 0,
                "20_Q4": 0,
                "21_Q1": 0,
                "21_Q2": 0,
                "21_Q3": 0,
            }
        ]
    },
    {
        id: "KINGSTON",
        values: [
            {
                "14_Q1":73,
                "14_Q2": 166,
                "14_Q3": 87,
                "14_Q4": 129,
                "15_Q1": 123,
                "15_Q2": 138,
                "15_Q3": 172,
                "15_Q4": 174,
                "16_Q1": 131,
                "16_Q2": 103,
                "16_Q3": 185,
                "16_Q4": 102,
                "17_Q1": 136,
                "17_Q2": 191,
                "17_Q3": 127,
                "17_Q4": 157,
                "18_Q1": 106,
                "18_Q2": 137,
                "18_Q3": 148,
                "18_Q4": 111,
                "19_Q1": 185,
                "19_Q2": 72,
                "19_Q3": 100,
                "19_Q4": 118,
                "20_Q1": 128,
                "20_Q2": 83,
                "20_Q3": 89,
                "20_Q4": 129,
                "21_Q1": 103,
                "21_Q2": 166,
                "21_Q3": 142,
            }
        ]
    },
    {
        id: "NARRABUNDAH",
        values: [
            {
                "14_Q1":119,
                "14_Q2": 213,
                "14_Q3": 208,
                "14_Q4": 145,
                "15_Q1": 127,
                "15_Q2": 202,
                "15_Q3": 227,
                "15_Q4": 191,
                "16_Q1": 223,
                "16_Q2": 175,
                "16_Q3": 151,
                "16_Q4": 214,
                "17_Q1": 121,
                "17_Q2": 239,
                "17_Q3": 182,
                "17_Q4": 225,
                "18_Q1": 200,
                "18_Q2": 242,
                "18_Q3": 323,
                "18_Q4": 171,
                "19_Q1": 125,
                "19_Q2": 119,
                "19_Q3": 101,
                "19_Q4": 70,
                "20_Q1": 92,
                "20_Q2": 127,
                "20_Q3": 105,
                "20_Q4": 136,
                "21_Q1": 133,
                "21_Q2": 119,
                "21_Q3": 77,
            }
        ]
    },
    {
        id: "OAKS ESTATE",
        values: [
            {
                "14_Q1":13,
                "14_Q2": 11,
                "14_Q3": 23,
                "14_Q4": 24,
                "15_Q1": 22,
                "15_Q2": 10,
                "15_Q3": 26,
                "15_Q4": 16,
                "16_Q1": 8,
                "16_Q2": 34,
                "16_Q3": 7,
                "16_Q4": 16,
                "17_Q1": 6,
                "17_Q2": 15,
                "17_Q3": 15,
                "17_Q4": 18,
                "18_Q1": 23,
                "18_Q2": 18,
                "18_Q3": 13,
                "18_Q4": 10,
                "19_Q1": 23,
                "19_Q2": 18,
                "19_Q3": 24,
                "19_Q4": 38,
                "20_Q1": 40,
                "20_Q2": 23,
                "20_Q3": 21,
                "20_Q4": 12,
                "21_Q1": 10,
                "21_Q2": 11,
                "21_Q3": 14,
            }
        ]
    },
    {
        id: "PIALLIGO",
        values: [
            {
                "14_Q1":25,
                "14_Q2": 23,
                "14_Q3": 39,
                "14_Q4": 35,
                "15_Q1": 37,
                "15_Q2": 56,
                "15_Q3": 69,
                "15_Q4": 25,
                "16_Q1": 52,
                "16_Q2": 37,
                "16_Q3": 49,
                "16_Q4": 24,
                "17_Q1": 30,
                "17_Q2": 29,
                "17_Q3": 37,
                "17_Q4": 20,
                "18_Q1": 23,
                "18_Q2": 20,
                "18_Q3": 25,
                "18_Q4": 16,
                "19_Q1": 16,
                "19_Q2": 5,
                "19_Q3": 19,
                "19_Q4": 14,
                "20_Q1": 22,
                "20_Q2": 30,
                "20_Q3": 17,
                "20_Q4": 19,
                "21_Q1": 19,
                "21_Q2": 37,
                "21_Q3": 8,
            }
        ]
    },
    {
        id: "RED HILL",
        values: [
            {
                "14_Q1":79,
                "14_Q2": 70,
                "14_Q3": 105,
                "14_Q4": 82,
                "15_Q1": 79,
                "15_Q2": 99,
                "15_Q3": 103,
                "15_Q4": 128,
                "16_Q1": 96,
                "16_Q2": 136,
                "16_Q3": 121,
                "16_Q4": 85,
                "17_Q1": 65,
                "17_Q2": 90,
                "17_Q3": 41,
                "17_Q4": 61,
                "18_Q1": 39,
                "18_Q2": 46,
                "18_Q3": 60,
                "18_Q4": 46,
                "19_Q1": 45,
                "19_Q2": 49,
                "19_Q3": 31,
                "19_Q4": 46,
                "20_Q1": 52,
                "20_Q2": 42,
                "20_Q3": 75,
                "20_Q4": 43,
                "21_Q1": 27,
                "21_Q2": 52,
                "21_Q3": 27,
            }
        ]
    },
    {
        id: "SYMONSTON",
        values: [
            {
                "14_Q1":38,
                "14_Q2": 53,
                "14_Q3": 65,
                "14_Q4": 57,
                "15_Q1": 28,
                "15_Q2": 63,
                "15_Q3": 57,
                "15_Q4": 37,
                "16_Q1": 50,
                "16_Q2": 31,
                "16_Q3": 56,
                "16_Q4": 59,
                "17_Q1": 38,
                "17_Q2": 83,
                "17_Q3": 77,
                "17_Q4": 45,
                "18_Q1": 57,
                "18_Q2": 29,
                "18_Q3": 43,
                "18_Q4": 45,
                "19_Q1": 46,
                "19_Q2": 28,
                "19_Q3": 25,
                "19_Q4": 42,
                "20_Q1": 45,
                "20_Q2": 58,
                "20_Q3": 57,
                "20_Q4": 33,
                "21_Q1": 51,
                "21_Q2": 62,
                "21_Q3": 23,
            }
        ]
    },
    {
        id: "YARRALUMLA",
        values: [
            {
                "14_Q1":96,
                "14_Q2": 91,
                "14_Q3": 90,
                "14_Q4": 83,
                "15_Q1": 96,
                "15_Q2": 128,
                "15_Q3": 88,
                "15_Q4": 83,
                "16_Q1": 78,
                "16_Q2": 95,
                "16_Q3": 71,
                "16_Q4": 83,
                "17_Q1": 110,
                "17_Q2": 188,
                "17_Q3": 133,
                "17_Q4": 110,
                "18_Q1": 124,
                "18_Q2": 70,
                "18_Q3": 136,
                "18_Q4": 141,
                "19_Q1": 136,
                "19_Q2": 95,
                "19_Q3": 120,
                "19_Q4": 133,
                "20_Q1": 161,
                "20_Q2": 133,
                "20_Q3": 135,
                "20_Q4": 106,
                "21_Q1": 102,
                "21_Q2": 90,
                "21_Q3": 61,
            }
        ]
    },
    {
        id: "CHAPMAN",
        values: [
            {
                "14_Q1":20,
                "14_Q2": 16,
                "14_Q3": 26,
                "14_Q4": 42,
                "15_Q1": 38,
                "15_Q2": 35,
                "15_Q3": 28,
                "15_Q4": 25,
                "16_Q1": 173,
                "16_Q2": 16,
                "16_Q3": 26,
                "16_Q4": 21,
                "17_Q1": 23,
                "17_Q2": 21,
                "17_Q3": 31,
                "17_Q4": 40,
                "18_Q1": 21,
                "18_Q2": 20,
                "18_Q3": 20,
                "18_Q4": 19,
                "19_Q1": 19,
                "19_Q2": 33,
                "19_Q3": 34,
                "19_Q4": 41,
                "20_Q1": 33,
                "20_Q2": 13,
                "20_Q3": 12,
                "20_Q4": 15,
                "21_Q1": 24,
                "21_Q2": 23,
                "21_Q3": 8,
            }
        ]
    },
    {
        id: "DUFFY",
        values: [
            {
                "14_Q1":41,
                "14_Q2": 26,
                "14_Q3": 25,
                "14_Q4": 54,
                "15_Q1": 68,
                "15_Q2": 52,
                "15_Q3": 79,
                "15_Q4": 73,
                "16_Q1": 24,
                "16_Q2": 36,
                "16_Q3": 27,
                "16_Q4": 27,
                "17_Q1": 27,
                "17_Q2": 35,
                "17_Q3": 29,
                "17_Q4": 28,
                "18_Q1": 50,
                "18_Q2": 29,
                "18_Q3": 25,
                "18_Q4": 40,
                "19_Q1": 42,
                "19_Q2": 37,
                "19_Q3": 40,
                "19_Q4": 60,
                "20_Q1": 38,
                "20_Q2": 28,
                "20_Q3": 35,
                "20_Q4": 37,
                "21_Q1": 35,
                "21_Q2": 20,
                "21_Q3": 20,
            }
        ]
    },
    {
        id: "FISHER",
        values: [
            {
                "14_Q1":23,
                "14_Q2": 37,
                "14_Q3": 47,
                "14_Q4": 24,
                "15_Q1": 46,
                "15_Q2": 36,
                "15_Q3": 35,
                "15_Q4": 38,
                "16_Q1": 29,
                "16_Q2": 38,
                "16_Q3": 56,
                "16_Q4": 36,
                "17_Q1": 29,
                "17_Q2": 37,
                "17_Q3": 39,
                "17_Q4": 44,
                "18_Q1": 52,
                "18_Q2": 29,
                "18_Q3": 22,
                "18_Q4": 26,
                "19_Q1": 23,
                "19_Q2": 16,
                "19_Q3": 25,
                "19_Q4": 29,
                "20_Q1": 35,
                "20_Q2": 31,
                "20_Q3": 30,
                "20_Q4": 35,
                "21_Q1": 30,
                "21_Q2": 33,
                "21_Q3": 20,
            }
        ]
    },
    {
        id: "HOLDER",
        values: [
            {
                "14_Q1":48,
                "14_Q2": 23,
                "14_Q3": 33,
                "14_Q4": 35,
                "15_Q1": 29,
                "15_Q2": 32,
                "15_Q3": 45,
                "15_Q4": 72,
                "16_Q1": 24,
                "16_Q2": 69,
                "16_Q3": 26,
                "16_Q4": 27,
                "17_Q1": 31,
                "17_Q2": 17,
                "17_Q3": 26,
                "17_Q4": 25,
                "18_Q1": 33,
                "18_Q2": 25,
                "18_Q3": 15,
                "18_Q4": 19,
                "19_Q1": 39,
                "19_Q2": 28,
                "19_Q3": 23,
                "19_Q4": 27,
                "20_Q1": 30,
                "20_Q2": 39,
                "20_Q3": 35,
                "20_Q4": 23,
                "21_Q1": 22,
                "21_Q2": 26,
                "21_Q3": 22,
            }
        ]
    },
    {
        id: "RIVETT",
        values: [
            {
                "14_Q1":33,
                "14_Q2": 38,
                "14_Q3": 39,
                "14_Q4": 58,
                "15_Q1": 58,
                "15_Q2": 45,
                "15_Q3": 39,
                "15_Q4": 96,
                "16_Q1": 39,
                "16_Q2": 35,
                "16_Q3": 44,
                "16_Q4": 57,
                "17_Q1": 51,
                "17_Q2": 39,
                "17_Q3": 57,
                "17_Q4": 36,
                "18_Q1": 27,
                "18_Q2": 34,
                "18_Q3": 37,
                "18_Q4": 47,
                "19_Q1": 32,
                "19_Q2": 52,
                "19_Q3": 58,
                "19_Q4": 65,
                "20_Q1": 51,
                "20_Q2": 36,
                "20_Q3": 34,
                "20_Q4": 29,
                "21_Q1": 26,
                "21_Q2": 51,
                "21_Q3": 22,
            }
        ]
    },
    {
        id: "STIRLING",
        values: [
            {
                "14_Q1":23,
                "14_Q2": 34,
                "14_Q3": 34,
                "14_Q4": 36,
                "15_Q1": 35,
                "15_Q2": 31,
                "15_Q3": 82,
                "15_Q4": 21,
                "16_Q1": 33,
                "16_Q2": 40,
                "16_Q3": 20,
                "16_Q4": 34,
                "17_Q1": 28,
                "17_Q2": 25,
                "17_Q3": 41,
                "17_Q4": 44,
                "18_Q1": 36,
                "18_Q2": 21,
                "18_Q3": 28,
                "18_Q4": 27,
                "19_Q1": 22,
                "19_Q2": 39,
                "19_Q3": 42,
                "19_Q4": 50,
                "20_Q1": 25,
                "20_Q2": 38,
                "20_Q3": 40,
                "20_Q4": 28,
                "21_Q1": 22,
                "21_Q2": 27,
                "21_Q3": 19,
            }
        ]
    },
    {
        id: "STROMLO",
        values: [
            {
                "14_Q1":19,
                "14_Q2": 31,
                "14_Q3": 21,
                "14_Q4": 13,
                "15_Q1": 21,
                "15_Q2": 22,
                "15_Q3": 34,
                "15_Q4": 33,
                "16_Q1": 28,
                "16_Q2": 35,
                "16_Q3": 35,
                "16_Q4": 33,
                "17_Q1": 46,
                "17_Q2": 35,
                "17_Q3": 46,
                "17_Q4": 39,
                "18_Q1": 45,
                "18_Q2": 30,
                "18_Q3": 26,
                "18_Q4": 48,
                "19_Q1": 44,
                "19_Q2": 67,
                "19_Q3": 27,
                "19_Q4": 49,
                "20_Q1": 79,
                "20_Q2": 38,
                "20_Q3": 28,
                "20_Q4": 48,
                "21_Q1": 35,
                "21_Q2": 43,
                "21_Q3": 15,
            }
        ]
    },
    {
        id: "WARAMANGA",
        values: [
            {
                "14_Q1":44,
                "14_Q2": 40,
                "14_Q3": 61,
                "14_Q4": 48,
                "15_Q1": 61,
                "15_Q2": 76,
                "15_Q3": 37,
                "15_Q4": 51,
                "16_Q1": 38,
                "16_Q2": 71,
                "16_Q3": 40,
                "16_Q4": 35,
                "17_Q1": 36,
                "17_Q2": 33,
                "17_Q3": 65,
                "17_Q4": 55,
                "18_Q1": 49,
                "18_Q2": 22,
                "18_Q3": 20,
                "18_Q4": 47,
                "19_Q1": 24,
                "19_Q2": 35,
                "19_Q3": 21,
                "19_Q4": 50,
                "20_Q1": 29,
                "20_Q2": 65,
                "20_Q3": 33,
                "20_Q4": 35,
                "21_Q1": 29,
                "21_Q2": 28,
                "21_Q3": 51,
            }
        ]
    },
    {
        id: "WESTON",
        values: [
            {
                "14_Q1":153,
                "14_Q2": 116,
                "14_Q3": 145,
                "14_Q4": 120,
                "15_Q1": 112,
                "15_Q2": 144,
                "15_Q3": 123,
                "15_Q4": 149,
                "16_Q1": 144,
                "16_Q2": 138,
                "16_Q3": 125,
                "16_Q4": 120,
                "17_Q1": 109,
                "17_Q2": 117,
                "17_Q3": 131,
                "17_Q4": 156,
                "18_Q1": 120,
                "18_Q2": 118,
                "18_Q3": 108,
                "18_Q4": 93,
                "19_Q1": 77,
                "19_Q2": 91,
                "19_Q3": 120,
                "19_Q4": 141,
                "20_Q1": 151,
                "20_Q2": 160,
                "20_Q3": 126,
                "20_Q4": 111,
                "21_Q1": 116,
                "21_Q2": 102,
                "21_Q3": 98,
            }
        ]
    },
    {
        id: "COOMBS",
        values: [
            {
                "14_Q1":2,
                "14_Q2": 2,
                "14_Q3": 6,
                "14_Q4": 0,
                "15_Q1": 15,
                "15_Q2": 9,
                "15_Q3": 17,
                "15_Q4": 31,
                "16_Q1": 43,
                "16_Q2": 23,
                "16_Q3": 32,
                "16_Q4": 25,
                "17_Q1": 30,
                "17_Q2": 61,
                "17_Q3": 118,
                "17_Q4": 106,
                "18_Q1": 48,
                "18_Q2": 94,
                "18_Q3": 57,
                "18_Q4": 45,
                "19_Q1": 58,
                "19_Q2": 76,
                "19_Q3": 59,
                "19_Q4": 86,
                "20_Q1": 66,
                "20_Q2": 60,
                "20_Q3": 70,
                "20_Q4": 69,
                "21_Q1": 52,
                "21_Q2": 68,
                "21_Q3": 48,
            }
        ]
    },
    {
        id: "DENMAN PROSPECT",
        values: [
            {
                "14_Q1":0,
                "14_Q2": 0,
                "14_Q3": 0,
                "14_Q4": 0,
                "15_Q1": 0,
                "15_Q2": 0,
                "15_Q3": 2,
                "15_Q4": 1,
                "16_Q1": 0,
                "16_Q2": 2,
                "16_Q3": 7,
                "16_Q4": 7,
                "17_Q1": 5,
                "17_Q2": 16,
                "17_Q3": 27,
                "17_Q4": 28,
                "18_Q1": 19,
                "18_Q2": 16,
                "18_Q3": 30,
                "18_Q4": 19,
                "19_Q1": 46,
                "19_Q2": 35,
                "19_Q3": 30,
                "19_Q4": 28,
                "20_Q1": 40,
                "20_Q2": 28,
                "20_Q3": 20,
                "20_Q4": 41,
                "21_Q1": 20,
                "21_Q2": 24,
                "21_Q3": 29,
            }
        ]
    },
    {
        id: "MOLONGLO",
        values: [
            {
                "14_Q1":0,
                "14_Q2": 1,
                "14_Q3": 7,
                "14_Q4": 0,
                "15_Q1": 0,
                "15_Q2": 0,
                "15_Q3": 3,
                "15_Q4": 5,
                "16_Q1": 1,
                "16_Q2": 1,
                "16_Q3": 2,
                "16_Q4": 2,
                "17_Q1": 4,
                "17_Q2": 1,
                "17_Q3": 1,
                "17_Q4": 3,
                "18_Q1": 5,
                "18_Q2": 4,
                "18_Q3": 2,
                "18_Q4": 8,
                "19_Q1": 6,
                "19_Q2": 6,
                "19_Q3": 3,
                "19_Q4": 10,
                "20_Q1": 17,
                "20_Q2": 6,
                "20_Q3": 42,
                "20_Q4": 71,
                "21_Q1": 48,
                "21_Q2": 51,
                "21_Q3": 12,
            }
        ]
    },
    {
        id: "WRIGHT",
        values: [
            {
                "14_Q1":11,
                "14_Q2": 12,
                "14_Q3": 22,
                "14_Q4": 18,
                "15_Q1": 34,
                "15_Q2": 40,
                "15_Q3": 105,
                "15_Q4": 42,
                "16_Q1": 38,
                "16_Q2": 87,
                "16_Q3": 109,
                "16_Q4": 49,
                "17_Q1": 97,
                "17_Q2": 69,
                "17_Q3": 92,
                "17_Q4": 58,
                "18_Q1": 30,
                "18_Q2": 38,
                "18_Q3": 49,
                "18_Q4": 37,
                "19_Q1": 26,
                "19_Q2": 63,
                "19_Q3": 35,
                "19_Q4": 69,
                "20_Q1": 59,
                "20_Q2": 23,
                "20_Q3": 38,
                "20_Q4": 60,
                "21_Q1": 44,
                "21_Q2": 51,
                "21_Q3": 28,
            }
        ]
    },
    {
        id: "CHIFLEY",
        values: [
            {
                "14_Q1":27,
                "14_Q2": 64,
                "14_Q3": 38,
                "14_Q4": 43,
                "15_Q1": 42,
                "15_Q2": 56,
                "15_Q3": 55,
                "15_Q4": 49,
                "16_Q1": 38,
                "16_Q2": 69,
                "16_Q3": 54,
                "16_Q4": 41,
                "17_Q1": 56,
                "17_Q2": 31,
                "17_Q3": 47,
                "17_Q4": 57,
                "18_Q1": 48,
                "18_Q2": 77,
                "18_Q3": 38,
                "18_Q4": 23,
                "19_Q1": 30,
                "19_Q2": 54,
                "19_Q3": 24,
                "19_Q4": 45,
                "20_Q1": 20,
                "20_Q2": 37,
                "20_Q3": 35,
                "20_Q4": 28,
                "21_Q1": 21,
                "21_Q2": 37,
                "21_Q3": 37,
            }
        ]
    },
    {
        id: "CURTIN",
        values: [
            {
                "14_Q1":135,
                "14_Q2": 168,
                "14_Q3": 138,
                "14_Q4": 124,
                "15_Q1": 72,
                "15_Q2": 74,
                "15_Q3": 97,
                "15_Q4": 105,
                "16_Q1": 65,
                "16_Q2": 120,
                "16_Q3": 136,
                "16_Q4": 77,
                "17_Q1": 121,
                "17_Q2": 128,
                "17_Q3": 144,
                "17_Q4": 167,
                "18_Q1": 175,
                "18_Q2": 147,
                "18_Q3": 114,
                "18_Q4": 133,
                "19_Q1": 122,
                "19_Q2": 117,
                "19_Q3": 93,
                "19_Q4": 114,
                "20_Q1": 105,
                "20_Q2": 103,
                "20_Q3": 61,
                "20_Q4": 62,
                "21_Q1": 100,
                "21_Q2": 113,
                "21_Q3": 52,
            }
        ]
    },
    {
        id: "FARRER",
        values: [
            {
                "14_Q1":25,
                "14_Q2": 54,
                "14_Q3": 41,
                "14_Q4": 41,
                "15_Q1": 20,
                "15_Q2": 32,
                "15_Q3": 31,
                "15_Q4": 40,
                "16_Q1": 41,
                "16_Q2": 17,
                "16_Q3": 33,
                "16_Q4": 29,
                "17_Q1": 17,
                "17_Q2": 37,
                "17_Q3": 40,
                "17_Q4": 40,
                "18_Q1": 47,
                "18_Q2": 30,
                "18_Q3": 21,
                "18_Q4": 32,
                "19_Q1": 28,
                "19_Q2": 34,
                "19_Q3": 23,
                "19_Q4": 37,
                "20_Q1": 30,
                "20_Q2": 23,
                "20_Q3": 19,
                "20_Q4": 21,
                "21_Q1": 34,
                "21_Q2": 27,
                "21_Q3": 17,
            }
        ]
    },
    {
        id: "GARRAN",
        values: [
            {
                "14_Q1":123,
                "14_Q2": 81,
                "14_Q3": 70,
                "14_Q4": 85,
                "15_Q1": 91,
                "15_Q2": 93,
                "15_Q3": 62,
                "15_Q4": 68,
                "16_Q1": 53,
                "16_Q2": 72,
                "16_Q3": 84,
                "16_Q4": 63,
                "17_Q1": 104,
                "17_Q2": 91,
                "17_Q3": 73,
                "17_Q4": 63,
                "18_Q1": 119,
                "18_Q2": 72,
                "18_Q3": 83,
                "18_Q4": 89,
                "19_Q1": 63,
                "19_Q2": 90,
                "19_Q3": 69,
                "19_Q4": 86,
                "20_Q1": 119,
                "20_Q2": 69,
                "20_Q3": 50,
                "20_Q4": 71,
                "21_Q1": 68,
                "21_Q2": 56,
                "21_Q3": 49,
            }
        ]
    },
    {
        id: "HUGHES",
        values: [
            {
                "14_Q1":35,
                "14_Q2": 32,
                "14_Q3": 53,
                "14_Q4": 28,
                "15_Q1": 30,
                "15_Q2": 35,
                "15_Q3": 32,
                "15_Q4": 26,
                "16_Q1": 17,
                "16_Q2": 34,
                "16_Q3": 42,
                "16_Q4": 40,
                "17_Q1": 39,
                "17_Q2": 49,
                "17_Q3": 32,
                "17_Q4": 21,
                "18_Q1": 39,
                "18_Q2": 40,
                "18_Q3": 28,
                "18_Q4": 30,
                "19_Q1": 35,
                "19_Q2": 18,
                "19_Q3": 20,
                "19_Q4": 28,
                "20_Q1": 18,
                "20_Q2": 31,
                "20_Q3": 21,
                "20_Q4": 34,
                "21_Q1": 12,
                "21_Q2": 21,
                "21_Q3": 28,
            }
        ]
    },
    {
        id: "ISAACS",
        values: [
            {
                "14_Q1":32,
                "14_Q2": 28,
                "14_Q3": 26,
                "14_Q4": 18,
                "15_Q1": 19,
                "15_Q2": 35,
                "15_Q3": 24,
                "15_Q4": 16,
                "16_Q1": 10,
                "16_Q2": 31,
                "16_Q3": 24,
                "16_Q4": 18,
                "17_Q1": 22,
                "17_Q2": 25,
                "17_Q3": 39,
                "17_Q4": 28,
                "18_Q1": 29,
                "18_Q2": 34,
                "18_Q3": 31,
                "18_Q4": 16,
                "19_Q1": 7,
                "19_Q2": 14,
                "19_Q3": 19,
                "19_Q4": 29,
                "20_Q1": 24,
                "20_Q2": 19,
                "20_Q3": 14,
                "20_Q4": 13,
                "21_Q1": 15,
                "21_Q2": 15,
                "21_Q3": 21,
            }
        ]
    },
    {
        id: "LYONS",
        values: [
            {
                "14_Q1":72,
                "14_Q2": 100,
                "14_Q3": 55,
                "14_Q4": 67,
                "15_Q1": 63,
                "15_Q2": 116,
                "15_Q3": 103,
                "15_Q4": 66,
                "16_Q1": 53,
                "16_Q2": 66,
                "16_Q3": 88,
                "16_Q4": 73,
                "17_Q1": 133,
                "17_Q2": 114,
                "17_Q3": 96,
                "17_Q4": 89,
                "18_Q1": 73,
                "18_Q2": 114,
                "18_Q3": 66,
                "18_Q4": 66,
                "19_Q1": 33,
                "19_Q2": 54,
                "19_Q3": 43,
                "19_Q4": 38,
                "20_Q1": 52,
                "20_Q2": 41,
                "20_Q3": 43,
                "20_Q4": 36,
                "21_Q1": 38,
                "21_Q2": 26,
                "21_Q3": 33,
            }
        ]
    },
    {
        id: "MAWSON",
        values: [
            {
                "14_Q1":87,
                "14_Q2": 86,
                "14_Q3": 124,
                "14_Q4": 68,
                "15_Q1": 99,
                "15_Q2": 80,
                "15_Q3": 122,
                "15_Q4": 144,
                "16_Q1": 71,
                "16_Q2": 87,
                "16_Q3": 107,
                "16_Q4": 95,
                "17_Q1": 87,
                "17_Q2": 76,
                "17_Q3": 118,
                "17_Q4": 114,
                "18_Q1": 98,
                "18_Q2": 140,
                "18_Q3": 94,
                "18_Q4": 77,
                "19_Q1": 79,
                "19_Q2": 76,
                "19_Q3": 61,
                "19_Q4": 39,
                "20_Q1": 62,
                "20_Q2": 59,
                "20_Q3": 56,
                "20_Q4": 54,
                "21_Q1": 75,
                "21_Q2": 41,
                "21_Q3": 53,
            }
        ]
    },
    {
        id: "O'MALLEY",
        values: [
            {
                "14_Q1":10,
                "14_Q2": 8,
                "14_Q3": 12,
                "14_Q4": 12,
                "15_Q1": 12,
                "15_Q2": 22,
                "15_Q3": 6,
                "15_Q4": 6,
                "16_Q1": 18,
                "16_Q2": 12,
                "16_Q3": 11,
                "16_Q4": 9,
                "17_Q1": 13,
                "17_Q2": 10,
                "17_Q3": 23,
                "17_Q4": 13,
                "18_Q1": 9,
                "18_Q2": 7,
                "18_Q3": 15,
                "18_Q4": 4,
                "19_Q1": 7,
                "19_Q2": 5,
                "19_Q3": 5,
                "19_Q4": 9,
                "20_Q1": 7,
                "20_Q2": 7,
                "20_Q3": 10,
                "20_Q4": 15,
                "21_Q1": 2,
                "21_Q2": 6,
                "21_Q3": 2,
            }
        ]
    },
    {
        id: "PEARCE",
        values: [
            {
                "14_Q1":26,
                "14_Q2": 51,
                "14_Q3": 52,
                "14_Q4": 63,
                "15_Q1": 54,
                "15_Q2": 25,
                "15_Q3": 29,
                "15_Q4": 31,
                "16_Q1": 40,
                "16_Q2": 27,
                "16_Q3": 25,
                "16_Q4": 28,
                "17_Q1": 23,
                "17_Q2": 32,
                "17_Q3": 43,
                "17_Q4": 27,
                "18_Q1": 30,
                "18_Q2": 39,
                "18_Q3": 41,
                "18_Q4": 38,
                "19_Q1": 23,
                "19_Q2": 30,
                "19_Q3": 20,
                "19_Q4": 30,
                "20_Q1": 20,
                "20_Q2": 15,
                "20_Q3": 19,
                "20_Q4": 11,
                "21_Q1": 20,
                "21_Q2": 26,
                "21_Q3": 13,
            }
        ]
    },
    {
        id: "PHILLIP",
        values: [
            {
                "14_Q1":293,
                "14_Q2": 289,
                "14_Q3": 428,
                "14_Q4": 429,
                "15_Q1": 296,
                "15_Q2": 357,
                "15_Q3": 361,
                "15_Q4": 378,
                "16_Q1": 251,
                "16_Q2": 302,
                "16_Q3": 344,
                "16_Q4": 358,
                "17_Q1": 335,
                "17_Q2": 336,
                "17_Q3": 403,
                "17_Q4": 394,
                "18_Q1": 385,
                "18_Q2": 344,
                "18_Q3": 282,
                "18_Q4": 287,
                "19_Q1": 242,
                "19_Q2": 281,
                "19_Q3": 242,
                "19_Q4": 254,
                "20_Q1": 261,
                "20_Q2": 218,
                "20_Q3": 184,
                "20_Q4": 205,
                "21_Q1": 243,
                "21_Q2": 233,
                "21_Q3": 170,
            }
        ]
    },
    {
        id: "TORRENS",
        values: [
            {
                "14_Q1":18,
                "14_Q2": 22,
                "14_Q3": 27,
                "14_Q4": 33,
                "15_Q1": 23,
                "15_Q2": 26,
                "15_Q3": 22,
                "15_Q4": 21,
                "16_Q1": 20,
                "16_Q2": 16,
                "16_Q3": 26,
                "16_Q4": 15,
                "17_Q1": 17,
                "17_Q2": 23,
                "17_Q3": 24,
                "17_Q4": 19,
                "18_Q1": 12,
                "18_Q2": 15,
                "18_Q3": 21,
                "18_Q4": 9,
                "19_Q1": 36,
                "19_Q2": 21,
                "19_Q3": 22,
                "19_Q4": 33,
                "20_Q1": 18,
                "20_Q2": 20,
                "20_Q3": 18,
                "20_Q4": 28,
                "21_Q1": 13,
                "21_Q2": 20,
                "21_Q3": 16,
            }
        ]
    },
    {
        id: "BANKS",
        values: [
            {
                "14_Q1":48,
                "14_Q2": 47,
                "14_Q3": 36,
                "14_Q4": 51,
                "15_Q1": 65,
                "15_Q2": 73,
                "15_Q3": 62,
                "15_Q4": 71,
                "16_Q1": 38,
                "16_Q2": 68,
                "16_Q3": 68,
                "16_Q4": 40,
                "17_Q1": 36,
                "17_Q2": 48,
                "17_Q3": 44,
                "17_Q4": 58,
                "18_Q1": 42,
                "18_Q2": 31,
                "18_Q3": 41,
                "18_Q4": 41,
                "19_Q1": 60,
                "19_Q2": 37,
                "19_Q3": 71,
                "19_Q4": 46,
                "20_Q1": 45,
                "20_Q2": 36,
                "20_Q3": 41,
                "20_Q4": 36,
                "21_Q1": 52,
                "21_Q2": 62,
                "21_Q3": 42,
            }
        ]
    },
    {
        id: "BONYTHON",
        values: [
            {
                "14_Q1":60,
                "14_Q2": 85,
                "14_Q3": 92,
                "14_Q4": 84,
                "15_Q1": 62,
                "15_Q2": 51,
                "15_Q3": 73,
                "15_Q4": 45,
                "16_Q1": 37,
                "16_Q2": 67,
                "16_Q3": 91,
                "16_Q4": 86,
                "17_Q1": 92,
                "17_Q2": 82,
                "17_Q3": 106,
                "17_Q4": 58,
                "18_Q1": 78,
                "18_Q2": 70,
                "18_Q3": 56,
                "18_Q4": 51,
                "19_Q1": 32,
                "19_Q2": 60,
                "19_Q3": 58,
                "19_Q4": 48,
                "20_Q1": 56,
                "20_Q2": 65,
                "20_Q3": 40,
                "20_Q4": 42,
                "21_Q1": 62,
                "21_Q2": 29,
                "21_Q3": 29,
            }
        ]
    },
    {
        id: "CALWELL",
        values: [
            {
                "14_Q1":83,
                "14_Q2": 139,
                "14_Q3": 141,
                "14_Q4": 112,
                "15_Q1": 122,
                "15_Q2": 144,
                "15_Q3": 121,
                "15_Q4": 123,
                "16_Q1": 97,
                "16_Q2": 100,
                "16_Q3": 92,
                "16_Q4": 117,
                "17_Q1": 98,
                "17_Q2": 101,
                "17_Q3": 121,
                "17_Q4": 95,
                "18_Q1": 123,
                "18_Q2": 108,
                "18_Q3": 134,
                "18_Q4": 92,
                "19_Q1": 101,
                "19_Q2": 110,
                "19_Q3": 75,
                "19_Q4": 110,
                "20_Q1": 86,
                "20_Q2": 106,
                "20_Q3": 71,
                "20_Q4": 81,
                "21_Q1": 111,
                "21_Q2": 94,
                "21_Q3": 48,
            }
        ]
    },
    {
        id: "CHISHOLM",
        values: [
            {
                "14_Q1":115,
                "14_Q2": 126,
                "14_Q3": 123,
                "14_Q4": 98,
                "15_Q1": 102,
                "15_Q2": 169,
                "15_Q3": 98,
                "15_Q4": 108,
                "16_Q1": 120,
                "16_Q2": 149,
                "16_Q3": 122,
                "16_Q4": 133,
                "17_Q1": 120,
                "17_Q2": 134,
                "17_Q3": 120,
                "17_Q4": 99,
                "18_Q1": 92,
                "18_Q2": 96,
                "18_Q3": 103,
                "18_Q4": 63,
                "19_Q1": 108,
                "19_Q2": 96,
                "19_Q3": 116,
                "19_Q4": 103,
                "20_Q1": 124,
                "20_Q2": 126,
                "20_Q3": 131,
                "20_Q4": 93,
                "21_Q1": 55,
                "21_Q2": 58,
                "21_Q3": 55,
            }
        ]
    },
    {
        id: "CONDER",
        values: [
            {
                "14_Q1":85,
                "14_Q2": 96,
                "14_Q3": 77,
                "14_Q4": 106,
                "15_Q1": 104,
                "15_Q2": 111,
                "15_Q3": 97,
                "15_Q4": 120,
                "16_Q1": 98,
                "16_Q2": 83,
                "16_Q3": 131,
                "16_Q4": 74,
                "17_Q1": 82,
                "17_Q2": 95,
                "17_Q3": 99,
                "17_Q4": 84,
                "18_Q1": 85,
                "18_Q2": 74,
                "18_Q3": 76,
                "18_Q4": 83,
                "19_Q1": 88,
                "19_Q2": 61,
                "19_Q3": 106,
                "19_Q4": 74,
                "20_Q1": 99,
                "20_Q2": 100,
                "20_Q3": 53,
                "20_Q4": 81,
                "21_Q1": 100,
                "21_Q2": 65,
                "21_Q3": 51,
            }
        ]
    },
    {
        id: "FADDEN",
        values: [
            {
                "14_Q1":25,
                "14_Q2": 36,
                "14_Q3": 46,
                "14_Q4": 34,
                "15_Q1": 48,
                "15_Q2": 49,
                "15_Q3": 55,
                "15_Q4": 43,
                "16_Q1": 36,
                "16_Q2": 39,
                "16_Q3": 37,
                "16_Q4": 34,
                "17_Q1": 40,
                "17_Q2": 48,
                "17_Q3": 44,
                "17_Q4": 27,
                "18_Q1": 9,
                "18_Q2": 30,
                "18_Q3": 28,
                "18_Q4": 12,
                "19_Q1": 35,
                "19_Q2": 15,
                "19_Q3": 17,
                "19_Q4": 53,
                "20_Q1": 33,
                "20_Q2": 16,
                "20_Q3": 15,
                "20_Q4": 23,
                "21_Q1": 15,
                "21_Q2": 22,
                "21_Q3": 23,
            }
        ]
    },
    {
        id: "GILMORE",
        values: [
            {
                "14_Q1":43,
                "14_Q2": 59,
                "14_Q3": 56,
                "14_Q4": 72,
                "15_Q1": 53,
                "15_Q2": 71,
                "15_Q3": 70,
                "15_Q4": 40,
                "16_Q1": 37,
                "16_Q2": 68,
                "16_Q3": 55,
                "16_Q4": 57,
                "17_Q1": 45,
                "17_Q2": 58,
                "17_Q3": 48,
                "17_Q4": 52,
                "18_Q1": 36,
                "18_Q2": 53,
                "18_Q3": 46,
                "18_Q4": 60,
                "19_Q1": 38,
                "19_Q2": 47,
                "19_Q3": 43,
                "19_Q4": 52,
                "20_Q1": 52,
                "20_Q2": 49,
                "20_Q3": 38,
                "20_Q4": 39,
                "21_Q1": 29,
                "21_Q2": 25,
                "21_Q3": 16,
            }
        ]
    },
    {
        id: "GORDON",
        values: [
            {
                "14_Q1":74,
                "14_Q2": 74,
                "14_Q3": 133,
                "14_Q4": 122,
                "15_Q1": 128,
                "15_Q2": 108,
                "15_Q3": 97,
                "15_Q4": 132,
                "16_Q1": 113,
                "16_Q2": 87,
                "16_Q3": 80,
                "16_Q4": 92,
                "17_Q1": 117,
                "17_Q2": 80,
                "17_Q3": 132,
                "17_Q4": 99,
                "18_Q1": 116,
                "18_Q2": 89,
                "18_Q3": 52,
                "18_Q4": 92,
                "19_Q1": 85,
                "19_Q2": 64,
                "19_Q3": 94,
                "19_Q4": 81,
                "20_Q1": 88,
                "20_Q2": 87,
                "20_Q3": 72,
                "20_Q4": 71,
                "21_Q1": 77,
                "21_Q2": 65,
                "21_Q3": 55,
            }
        ]
    },
    {
        id: "GOWRIE",
        values: [
            {
                "14_Q1":85,
                "14_Q2": 96,
                "14_Q3": 87,
                "14_Q4": 74,
                "15_Q1": 62,
                "15_Q2": 44,
                "15_Q3": 51,
                "15_Q4": 57,
                "16_Q1": 31,
                "16_Q2": 50,
                "16_Q3": 58,
                "16_Q4": 52,
                "17_Q1": 60,
                "17_Q2": 88,
                "17_Q3": 47,
                "17_Q4": 43,
                "18_Q1": 20,
                "18_Q2": 28,
                "18_Q3": 33,
                "18_Q4": 62,
                "19_Q1": 41,
                "19_Q2": 49,
                "19_Q3": 28,
                "19_Q4": 66,
                "20_Q1": 51,
                "20_Q2": 59,
                "20_Q3": 57,
                "20_Q4": 57,
                "21_Q1": 44,
                "21_Q2": 59,
                "21_Q3": 63,
            }
        ]
    },
    {
        id: "GREENWAY",
        values: [
            {
                "14_Q1":307,
                "14_Q2": 258,
                "14_Q3": 454,
                "14_Q4": 290,
                "15_Q1": 273,
                "15_Q2": 265,
                "15_Q3": 380,
                "15_Q4": 317,
                "16_Q1": 272,
                "16_Q2": 288,
                "16_Q3": 334,
                "16_Q4": 306,
                "17_Q1": 338,
                "17_Q2": 352,
                "17_Q3": 260,
                "17_Q4": 228,
                "18_Q1": 308,
                "18_Q2": 341,
                "18_Q3": 350,
                "18_Q4": 244,
                "19_Q1": 260,
                "19_Q2": 265,
                "19_Q3": 269,
                "19_Q4": 342,
                "20_Q1": 264,
                "20_Q2": 228,
                "20_Q3": 194,
                "20_Q4": 227,
                "21_Q1": 248,
                "21_Q2": 282,
                "21_Q3": 179,
            }
        ]
    },
    {
        id: "ISABELLA PLAINS",
        values: [
            {
                "14_Q1":69,
                "14_Q2": 100,
                "14_Q3": 73,
                "14_Q4": 89,
                "15_Q1": 53,
                "15_Q2": 73,
                "15_Q3": 66,
                "15_Q4": 78,
                "16_Q1": 78,
                "16_Q2": 76,
                "16_Q3": 70,
                "16_Q4": 45,
                "17_Q1": 72,
                "17_Q2": 128,
                "17_Q3": 84,
                "17_Q4": 74,
                "18_Q1": 80,
                "18_Q2": 62,
                "18_Q3": 77,
                "18_Q4": 69,
                "19_Q1": 68,
                "19_Q2": 56,
                "19_Q3": 70,
                "19_Q4": 70,
                "20_Q1": 59,
                "20_Q2": 93,
                "20_Q3": 60,
                "20_Q4": 40,
                "21_Q1": 64,
                "21_Q2": 53,
                "21_Q3": 46,
            }
        ]
    },
    {
        id: "KAMBAH",
        values: [
            {
                "14_Q1":308,
                "14_Q2": 259,
                "14_Q3": 357,
                "14_Q4": 292,
                "15_Q1": 455,
                "15_Q2": 476,
                "15_Q3": 420,
                "15_Q4": 366,
                "16_Q1": 330,
                "16_Q2": 354,
                "16_Q3": 441,
                "16_Q4": 394,
                "17_Q1": 427,
                "17_Q2": 471,
                "17_Q3": 468,
                "17_Q4": 357,
                "18_Q1": 345,
                "18_Q2": 352,
                "18_Q3": 329,
                "18_Q4": 349,
                "19_Q1": 352,
                "19_Q2": 331,
                "19_Q3": 317,
                "19_Q4": 397,
                "20_Q1": 326,
                "20_Q2": 269,
                "20_Q3": 210,
                "20_Q4": 293,
                "21_Q1": 204,
                "21_Q2": 176,
                "21_Q3": 155,
            }
        ]
    },
    {
        id: "MACARTHUR",
        values: [
            {
                "14_Q1":17,
                "14_Q2": 18,
                "14_Q3": 47,
                "14_Q4": 13,
                "15_Q1": 10,
                "15_Q2": 30,
                "15_Q3": 28,
                "15_Q4": 18,
                "16_Q1": 18,
                "16_Q2": 16,
                "16_Q3": 22,
                "16_Q4": 27,
                "17_Q1": 15,
                "17_Q2": 27,
                "17_Q3": 8,
                "17_Q4": 11,
                "18_Q1": 16,
                "18_Q2": 21,
                "18_Q3": 8,
                "18_Q4": 5,
                "19_Q1": 11,
                "19_Q2": 26,
                "19_Q3": 14,
                "19_Q4": 17,
                "20_Q1": 20,
                "20_Q2": 15,
                "20_Q3": 1,
                "20_Q4": 2,
                "21_Q1": 7,
                "21_Q2": 16,
                "21_Q3": 23,
            }
        ]
    },
    {
        id: "MONASH",
        values: [
            {
                "14_Q1":95,
                "14_Q2": 82,
                "14_Q3": 79,
                "14_Q4": 62,
                "15_Q1": 96,
                "15_Q2": 98,
                "15_Q3": 82,
                "15_Q4": 94,
                "16_Q1": 94,
                "16_Q2": 55,
                "16_Q3": 73,
                "16_Q4": 52,
                "17_Q1": 57,
                "17_Q2": 69,
                "17_Q3": 46,
                "17_Q4": 89,
                "18_Q1": 72,
                "18_Q2": 29,
                "18_Q3": 58,
                "18_Q4": 51,
                "19_Q1": 73,
                "19_Q2": 62,
                "19_Q3": 62,
                "19_Q4": 61,
                "20_Q1": 68,
                "20_Q2": 81,
                "20_Q3": 57,
                "20_Q4": 51,
                "21_Q1": 68,
                "21_Q2": 54,
                "21_Q3": 40,
            }
        ]
    },
    {
        id: "OXLEY",
        values: [
            {
                "14_Q1":28,
                "14_Q2": 25,
                "14_Q3": 33,
                "14_Q4": 37,
                "15_Q1": 23,
                "15_Q2": 32,
                "15_Q3": 35,
                "15_Q4": 32,
                "16_Q1": 38,
                "16_Q2": 33,
                "16_Q3": 52,
                "16_Q4": 28,
                "17_Q1": 28,
                "17_Q2": 36,
                "17_Q3": 26,
                "17_Q4": 40,
                "18_Q1": 23,
                "18_Q2": 36,
                "18_Q3": 28,
                "18_Q4": 39,
                "19_Q1": 30,
                "19_Q2": 21,
                "19_Q3": 22,
                "19_Q4": 15,
                "20_Q1": 35,
                "20_Q2": 27,
                "20_Q3": 17,
                "20_Q4": 9,
                "21_Q1": 25,
                "21_Q2": 14,
                "21_Q3": 13,
            }
        ]
    },
    {
        id: "RICHARDSON",
        values: [
            {
                "14_Q1":85,
                "14_Q2": 67,
                "14_Q3": 94,
                "14_Q4": 68,
                "15_Q1": 56,
                "15_Q2": 75,
                "15_Q3": 88,
                "15_Q4": 63,
                "16_Q1": 39,
                "16_Q2": 53,
                "16_Q3": 78,
                "16_Q4": 42,
                "17_Q1": 108,
                "17_Q2": 99,
                "17_Q3": 65,
                "17_Q4": 60,
                "18_Q1": 33,
                "18_Q2": 68,
                "18_Q3": 64,
                "18_Q4": 61,
                "19_Q1": 66,
                "19_Q2": 42,
                "19_Q3": 47,
                "19_Q4": 65,
                "20_Q1": 61,
                "20_Q2": 63,
                "20_Q3": 73,
                "20_Q4": 57,
                "21_Q1": 33,
                "21_Q2": 37,
                "21_Q3": 44,
            }
        ]
    },
    {
        id: "THEODORE",
        values: [
            {
                "14_Q1":51,
                "14_Q2": 53,
                "14_Q3": 64,
                "14_Q4": 57,
                "15_Q1": 42,
                "15_Q2": 50,
                "15_Q3": 66,
                "15_Q4": 63,
                "16_Q1": 32,
                "16_Q2": 58,
                "16_Q3": 59,
                "16_Q4": 50,
                "17_Q1": 45,
                "17_Q2": 48,
                "17_Q3": 50,
                "17_Q4": 51,
                "18_Q1": 27,
                "18_Q2": 47,
                "18_Q3": 33,
                "18_Q4": 30,
                "19_Q1": 45,
                "19_Q2": 58,
                "19_Q3": 36,
                "19_Q4": 54,
                "20_Q1": 33,
                "20_Q2": 30,
                "20_Q3": 39,
                "20_Q4": 38,
                "21_Q1": 35,
                "21_Q2": 36,
                "21_Q3": 40,
            }
        ]
    },
    {
        id: "WANNIASSA",
        values: [
            {
                "14_Q1":278,
                "14_Q2": 249,
                "14_Q3": 270,
                "14_Q4": 279,
                "15_Q1": 202,
                "15_Q2": 248,
                "15_Q3": 240,
                "15_Q4": 243,
                "16_Q1": 256,
                "16_Q2": 261,
                "16_Q3": 237,
                "16_Q4": 240,
                "17_Q1": 278,
                "17_Q2": 285,
                "17_Q3": 263,
                "17_Q4": 180,
                "18_Q1": 218,
                "18_Q2": 227,
                "18_Q3": 168,
                "18_Q4": 208,
                "19_Q1": 187,
                "19_Q2": 166,
                "19_Q3": 220,
                "19_Q4": 159,
                "20_Q1": 205,
                "20_Q2": 184,
                "20_Q3": 149,
                "20_Q4": 159,
                "21_Q1": 144,
                "21_Q2": 122,
                "21_Q3": 115,
            }
        ]
    },
    {
        id: "HUME",
        values: [
            {
                "14_Q1":92,
                "14_Q2": 114,
                "14_Q3": 141,
                "14_Q4": 75,
                "15_Q1": 73,
                "15_Q2": 108,
                "15_Q3": 111,
                "15_Q4": 92,
                "16_Q1": 113,
                "16_Q2": 114,
                "16_Q3": 105,
                "16_Q4": 87,
                "17_Q1": 61,
                "17_Q2": 158,
                "17_Q3": 76,
                "17_Q4": 78,
                "18_Q1": 91,
                "18_Q2": 114,
                "18_Q3": 134,
                "18_Q4": 91,
                "19_Q1": 118,
                "19_Q2": 74,
                "19_Q3": 102,
                "19_Q4": 67,
                "20_Q1": 76,
                "20_Q2": 198,
                "20_Q3": 88,
                "20_Q4": 71,
                "21_Q1": 94,
                "21_Q2": 106,
                "21_Q3": 45,
            }
        ]
    },
    {
        id: "THARWA",
        values: [
            {
                "14_Q1":1,
                "14_Q2": 5,
                "14_Q3": 8,
                "14_Q4": 0,
                "15_Q1": 2,
                "15_Q2": 8,
                "15_Q3": 10,
                "15_Q4": 6,
                "16_Q1": 8,
                "16_Q2": 3,
                "16_Q3": 3,
                "16_Q4": 22,
                "17_Q1": 6,
                "17_Q2": 32,
                "17_Q3": 9,
                "17_Q4": 8,
                "18_Q1": 24,
                "18_Q2": 11,
                "18_Q3": 4,
                "18_Q4": 11,
                "19_Q1": 8,
                "19_Q2": 0,
                "19_Q3": 7,
                "19_Q4": 6,
                "20_Q1": 10,
                "20_Q2": 4,
                "20_Q3": 7,
                "20_Q4": 6,
                "21_Q1": 7,
                "21_Q2": 8,
                "21_Q3": 0,
            }
        ]
    },
    {
        id: "URIARRA",
        values: [
            {
                "14_Q1":12,
                "14_Q2": 9,
                "14_Q3": 14,
                "14_Q4": 9,
                "15_Q1": 7,
                "15_Q2": 3,
                "15_Q3": 7,
                "15_Q4": 1,
                "16_Q1": 1,
                "16_Q2": 3,
                "16_Q3": 7,
                "16_Q4": 3,
                "17_Q1": 3,
                "17_Q2": 6,
                "17_Q3": 4,
                "17_Q4": 2,
                "18_Q1": 4,
                "18_Q2": 4,
                "18_Q3": 4,
                "18_Q4": 2,
                "19_Q1": 0,
                "19_Q2": 2,
                "19_Q3": 1,
                "19_Q4": 1,
                "20_Q1": 4,
                "20_Q2": 9,
                "20_Q3": 3,
                "20_Q4": 6,
                "21_Q1": 0,
                "21_Q2": 0,
                "21_Q3": 1,
            }
        ]
    },
    {
        id: "WILLIAMSDALE",
        values: [
            {
                "14_Q1":1,
                "14_Q2": 4,
                "14_Q3": 4,
                "14_Q4": 5,
                "15_Q1": 2,
                "15_Q2": 7,
                "15_Q3": 9,
                "15_Q4": 3,
                "16_Q1": 2,
                "16_Q2": 12,
                "16_Q3": 7,
                "16_Q4": 1,
                "17_Q1": 2,
                "17_Q2": 8,
                "17_Q3": 4,
                "17_Q4": 3,
                "18_Q1": 1,
                "18_Q2": 0,
                "18_Q3": 3,
                "18_Q4": 2,
                "19_Q1": 0,
                "19_Q2": 0,
                "19_Q3": 11,
                "19_Q4": 2,
                "20_Q1": 5,
                "20_Q2": 4,
                "20_Q3": 3,
                "20_Q4": 5,
                "21_Q1": 2,
                "21_Q2": 3,
                "21_Q3": 5,
            }
        ]
    },
    {
        id: "GREATERBELCONNEN",
        range: {m: 0, n:24},
        values: [
            {
                "14_Q1": 2577,
                "14_Q2": 2433,
                "14_Q3": 2574,
                "14_Q4": 2453,
                "15_Q1": 2379,
                "15_Q2": 2346,
                "15_Q3": 2502,
                "15_Q4": 2487,
                "16_Q1": 2246,
                "16_Q2": 2604,
                "16_Q3": 2817,
                "16_Q4": 2719,
                "17_Q1": 2926,
                "17_Q2": 2945,
                "17_Q3": 2878,
                "17_Q4": 2780,
                "18_Q1": 2727,
                "18_Q2": 2544,
                "18_Q3": 2993,
                "18_Q4": 2908,
                "19_Q1": 2961,
                "19_Q2": 2914,
                "19_Q3": 2624,
                "19_Q4": 2733,
                "20_Q1": 2908,
                "20_Q2": 2933,
                "20_Q3": 2664,
                "20_Q4": 2803,
                "21_Q1": 2245,
                "21_Q2": 2594,
                "21_Q3": 1669,
            }
        ]
    },
    {
        id: "GREATERGUNGAHLIN",
        range: {m: 25, n:43},
        values: [
            {
                "14_Q1":1089,
                "14_Q2": 1077,
                "14_Q3": 994,
                "14_Q4": 1052,
                "15_Q1": 976,
                "15_Q2": 1169,
                "15_Q3": 991,
                "15_Q4": 1340,
                "16_Q1": 1244,
                "16_Q2": 1313,
                "16_Q3": 1477,
                "16_Q4": 1255,
                "17_Q1": 1303,
                "17_Q2": 1355,
                "17_Q3": 1555,
                "17_Q4": 1540,
                "18_Q1": 1157,
                "18_Q2": 1197,
                "18_Q3": 1319,
                "18_Q4": 1064,
                "19_Q1": 1319,
                "19_Q2": 1294,
                "19_Q3": 1275,
                "19_Q4": 1514,
                "20_Q1": 1416,
                "20_Q2": 1317,
                "20_Q3": 1275,
                "20_Q4": 1314,
                "21_Q1": 1167,
                "21_Q2": 1406,
                "21_Q3": 961,
            }
        ]
    },
    {
        id: "INNERNORTH",
        range: {m: 44, n:61},
        values: [
            {
                "14_Q1":3172,
                "14_Q2": 2669,
                "14_Q3": 2872,
                "14_Q4": 2578,
                "15_Q1": 2494,
                "15_Q2": 2659,
                "15_Q3": 2599,
                "15_Q4": 2589,
                "16_Q1": 2532,
                "16_Q2": 2498,
                "16_Q3": 2594,
                "16_Q4": 2518,
                "17_Q1": 2921,
                "17_Q2": 2693,
                "17_Q3": 2628,
                "17_Q4": 2663,
                "18_Q1": 2633,
                "18_Q2": 2311,
                "18_Q3": 2507,
                "18_Q4": 2516,
                "19_Q1": 2732,
                "19_Q2": 2438,
                "19_Q3": 2157,
                "19_Q4": 2471,
                "20_Q1": 2508,
                "20_Q2": 1795,
                "20_Q3": 1906,
                "20_Q4": 2281,
                "21_Q1": 1972,
                "21_Q2": 2284,
                "21_Q3": 1935,
            }
        ]
    },
    {
        id: "INNERSOUTH",
        range: {m: 62, n:76},
        values: [
            {
                "14_Q1":1190,
                "14_Q2": 1276,
                "14_Q3": 1295,
                "14_Q4": 1248,
                "15_Q1": 1109,
                "15_Q2": 1451,
                "15_Q3": 1548,
                "15_Q4": 1415,
                "16_Q1": 1272,
                "16_Q2": 1381,
                "16_Q3": 1434,
                "16_Q4": 1373,
                "17_Q1": 1279,
                "17_Q2": 1596,
                "17_Q3": 1395,
                "17_Q4": 1409,
                "18_Q1": 1319,
                "18_Q2": 1250,
                "18_Q3": 1470,
                "18_Q4": 1233,
                "19_Q1": 1358,
                "19_Q2": 936,
                "19_Q3": 946,
                "19_Q4": 1110,
                "20_Q1": 1275,
                "20_Q2": 965,
                "20_Q3": 1092,
                "20_Q4": 989,
                "21_Q1": 993,
                "21_Q2": 1236,
                "21_Q3": 815,
            }
        ]
    },
    {
        id: "WESTONCREEK",
        range: {m: 77, n:85},
        values: [
            {
                "14_Q1":404,
                "14_Q2": 361,
                "14_Q3": 431,
                "14_Q4": 430,
                "15_Q1": 468,
                "15_Q2": 473,
                "15_Q3": 502,
                "15_Q4": 558,
                "16_Q1": 532,
                "16_Q2": 478,
                "16_Q3": 399,
                "16_Q4": 390,
                "17_Q1": 380,
                "17_Q2": 359,
                "17_Q3": 465,
                "17_Q4": 467,
                "18_Q1": 433,
                "18_Q2": 328,
                "18_Q3": 301,
                "18_Q4": 366,
                "19_Q1": 322,
                "19_Q2": 398,
                "19_Q3": 390,
                "19_Q4": 512,
                "20_Q1": 471,
                "20_Q2": 448,
                "20_Q3": 373,
                "20_Q4": 361,
                "21_Q1": 339,
                "21_Q2": 353,
                "21_Q3": 275,
            }
        ]
    },
    {
        id: "MOLONGLODISTRICT",
        range: {m: 86, n:89},
        values: [
            {
                "14_Q1":13,
                "14_Q2": 15,
                "14_Q3": 35,
                "14_Q4": 18,
                "15_Q1": 49,
                "15_Q2": 49,
                "15_Q3": 127,
                "15_Q4": 79,
                "16_Q1": 82,
                "16_Q2": 113,
                "16_Q3": 150,
                "16_Q4": 83,
                "17_Q1": 136,
                "17_Q2": 147,
                "17_Q3": 238,
                "17_Q4": 195,
                "18_Q1": 102,
                "18_Q2": 152,
                "18_Q3": 138,
                "18_Q4": 109,
                "19_Q1": 136,
                "19_Q2": 180,
                "19_Q3": 127,
                "19_Q4": 193,
                "20_Q1": 182,
                "20_Q2": 117,
                "20_Q3": 170,
                "20_Q4": 241,
                "21_Q1": 164,
                "21_Q2": 194,
                "21_Q3": 117,
            }
        ]
    },
    {
        id: "WODENCREEK",
        range: {m: 90, n:101},
        values: [
            {
                "14_Q1":883,
                "14_Q2": 983,
                "14_Q3": 1064,
                "14_Q4": 1011,
                "15_Q1": 821,
                "15_Q2": 951,
                "15_Q3": 944,
                "15_Q4": 950,
                "16_Q1": 677,
                "16_Q2": 853,
                "16_Q3": 974,
                "16_Q4": 846,
                "17_Q1": 967,
                "17_Q2": 952,
                "17_Q3": 1082,
                "17_Q4": 1032,
                "18_Q1": 1064,
                "18_Q2": 1059,
                "18_Q3": 834,
                "18_Q4": 804,
                "19_Q1": 705,
                "19_Q2": 794,
                "19_Q3": 641,
                "19_Q4": 742,
                "20_Q1": 736,
                "20_Q2": 642,
                "20_Q3": 530,
                "20_Q4": 578,
                "21_Q1": 641,
                "21_Q2": 621,
                "21_Q3": 491,
            }
        ]
    },
    {
        id: "TUGGERANONGCREEK",
        range: {m: 102, n:119},
        values: [
            {
                "14_Q1":1856,
                "14_Q2": 1869,
                "14_Q3": 2262,
                "14_Q4": 1940,
                "15_Q1": 1956,
                "15_Q2": 2167,
                "15_Q3": 2129,
                "15_Q4": 2013,
                "16_Q1": 1764,
                "16_Q2": 1905,
                "16_Q3": 2100,
                "16_Q4": 1869,
                "17_Q1": 2058,
                "17_Q2": 2249,
                "17_Q3": 2031,
                "17_Q4": 1705,
                "18_Q1": 1723,
                "18_Q2": 1762,
                "18_Q3": 1684,
                "18_Q4": 1612,
                "19_Q1": 1680,
                "19_Q2": 1566,
                "19_Q3": 1665,
                "19_Q4": 1813,
                "20_Q1": 1705,
                "20_Q2": 1634,
                "20_Q3": 1318,
                "20_Q4": 1399,
                "21_Q1": 1373,
                "21_Q2": 1269,
                "21_Q3": 1037,
            }
        ]
    },
    {
        id: "MISC",
        range: {m: 120, n:123},
        values: [
            {
                "14_Q1":106,
                "14_Q2": 132,
                "14_Q3": 167,
                "14_Q4": 89,
                "15_Q1": 84,
                "15_Q2": 126,
                "15_Q3": 137,
                "15_Q4": 102,
                "16_Q1": 124,
                "16_Q2": 132,
                "16_Q3": 122,
                "16_Q4": 113,
                "17_Q1": 72,
                "17_Q2": 204,
                "17_Q3": 93,
                "17_Q4": 91,
                "18_Q1": 120,
                "18_Q2": 129,
                "18_Q3": 145,
                "18_Q4": 106,
                "19_Q1": 126,
                "19_Q2": 76,
                "19_Q3": 121,
                "19_Q4": 76,
                "20_Q1": 95,
                "20_Q2": 215,
                "20_Q3": 101,
                "20_Q4": 88,
                "21_Q1": 103,
                "21_Q2": 117,
                "21_Q3": 51,
            }
        ]
    },

]